export const DOWNVOTED_PLACES = 'downvotedPlaces'
export const LAST_SELECTED_MODE = 'lastSelectedMode'
export const PREFERRED_LANGUAGE = 'preferredLanguage'
export const REGISTERED_NEWSLETTER = 'registeredNewsletter'
export const STORAGE_TEMP_USER_INFO = 'tempUserInfo'
export const SUBSCRIBE_SIGN_OUT = 'subscribeSignOut'
export const USER_COUNTRY = 'userCountry'
export const USER_POSTAL_CODE = 'userPostalCode'
export const TEMP_NEW_PLACE_INFO = 'tempNewPlaceInfo'
export const TEMP_PRODUCTS = 'tempProducts'
export const TEMP_ACTIVITIES = 'tempActivities'
export const NEW_PLACE_ID = 'newPlaceId'
export const SELECTED_ZIP = 'selectedZip'

export const safelyRetrieveStorage = key => {
  const value = localStorage.getItem(key)
  return value !== 'null' && value !== 'undefined' && value
}
