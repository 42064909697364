import React, { Component, createContext } from 'react'
import firebase from 'firebase/app'
import { ROLE_USER, User } from '../models/User'
import { STORAGE_TEMP_USER_INFO } from '../helpers/localStorageKeys'
import { fetchClientIPInfo, fetchSetUserInfo, fetchUser } from '../../api/sessionFetchers'

export const UserContext = createContext({ user: null })

export const generateUserDocument = async (user, newData) => {
  if (!user) return

  const editedTimestamp = firebase.firestore.Timestamp.fromDate(new Date())
  const auxUser = {
    editedTimestamp,
    email: user.email,
    emailVerified: user.emailVerified,
    displayName: user.displayName,
    photoURL: user.photoURL,
    phoneNumber: user.phoneNumber,
    createdTimestamp: editedTimestamp,
    role: ROLE_USER,
  }

  if (!newData?.postcode) {
    try {
      const [resultCountry, resultPostal] = await fetchClientIPInfo()
      auxUser.postcode = resultPostal
      auxUser.country = resultCountry
    } catch (e) {
      console.log(e)
      auxUser.country = 'CH'
    }
  }

  const response = {
    ...auxUser,
    ...newData,
  }

  try {
    await fetchSetUserInfo(user.uid, response).then(() => {
      localStorage.removeItem(STORAGE_TEMP_USER_INFO)
    })
  } catch (error) {
    console.error('Error creating user document', error)
  }
  return response
}

export const getUserDocument = async user => {
  if (!user.uid) return null
  try {
    let response = await fetchUser(user.uid)

    // If the user data is not in firebase yet, create user document
    if (!response) {
      response = await generateUserDocument(
        user,
        JSON.parse(localStorage.getItem(STORAGE_TEMP_USER_INFO))
      )
    }

    // If the email was verified when the user was not logged in, sync the firebase storage with the new value
    if (response.emailVerified !== user.emailVerified) {
      await fetchSetUserInfo(user.uid, { emailVerified: user.emailVerified })
      response.emailVerified = user.emailVerified
    }

    // Return the complete user data from the firebase storage
    return new User({
      uid: user.uid,
      ...response,
    })
  } catch (error) {
    console.error('Error fetching user', error)
  }
}

class UserProvider extends Component {
  render() {
    return (
      <UserContext.Provider value={this.props.user}>{this.props.children}</UserContext.Provider>
    )
  }
}

export default UserProvider
