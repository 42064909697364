import React from 'react'
import { Button, Col, Result, Row } from 'antd'
import { $, textStore } from '../../stores/localization/localization'
import './loginDrawer.less'
import { AppRoutes } from '../../utils/constants/Routing'
import { LocalizedButton } from '../../utils/localization/LocalizedButton'

const VerificationSentComponent = props => {
  const { keys } = textStore()

  return (
    <Row align='center' className='create-account'>
      <Col span={20}>
        <Result
          status='success'
          title={$(keys.firebase_signon_confirmEmail)}
          extra={
            props.onBack ? (
              <Button type='primary' onClick={props.onBack}>
                {$(keys.back)}
              </Button>
            ) : (
              <LocalizedButton type='primary' to={AppRoutes.Home}>
                {$(keys.firebase_signon_backToHome)}
              </LocalizedButton>
            )
          }
        />
      </Col>
    </Row>
  )
}

export default VerificationSentComponent
