import { useEffect, useState } from 'react'

export const useFirestoreDoc = (ref, setData) => {
  const [docState, setDocState] = useState({
    isLoading: true,
    doc: null,
  })

  useEffect(() => {
    if (ref) {
      return ref.onSnapshot(doc => {
        setData && setData({ id: doc.id, ...doc.data() })
        setDocState({
          isLoading: false,
          doc: { id: doc.id, ...doc.data() },
        })
      })
    }
  }, [])

  return docState
}

export const useFirestoreQuery = (ref, setData, hasPermission = true) => {
  const [docState, setDocState] = useState({
    isLoading: true,
    docs: [],
  })

  useEffect(() => {
    if (ref && hasPermission) {
      ref.onSnapshot(queryResult => {
        handleFirestoreQueryResponse(queryResult, setData, setDocState).then()
      })
    } else {
      setDocState({
        isLoading: false,
        docs: null,
      })
    }
  }, [])

  return docState
}

export const useFirestoreQueryGet = (ref, setData, hasPermission = true) => {
  const [docState, setDocState] = useState({
    isLoading: true,
    docs: [],
  })

  useEffect(() => {
    if (ref && hasPermission) {
      // We use *then* instead of *await* here, because this useEffect function can't be async
      ref.get().then(queryResult => {
        handleFirestoreQueryResponse(queryResult, setData, setDocState).then()
      })
    } else {
      setDocState({
        isLoading: false,
        docs: null,
      })
    }
  }, [])

  return docState
}

const handleFirestoreQueryResponse = async (queryResult, setData, setDocState) => {
  let docs = []
  queryResult.forEach(data => {
    docs.push({ id: data.id, ...data.data() })
  })
  // If setData returns a new value, update the state with it
  const auxNewData = await setData(docs)
  if (auxNewData) {
    setDocState({
      isLoading: false,
      docs: auxNewData,
    })
  } else {
    setDocState({
      isLoading: false,
      docs: docs,
    })
  }
}
