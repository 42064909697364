import React, { useContext, useEffect, useState } from 'react'
import { message } from 'antd'
import HeroMap from './components/HeroMap'
import HeroList from './components/HeroList'
import { db } from '../../api/firebase'
import { useFirestoreQuery } from '../../utils/hooks/useFirestore'
import { useHistory, useLocation } from 'react-router-dom'
import { useLoadScript } from '@react-google-maps/api'
import { $, textStore } from '../../stores/localization/localization'
import axios from 'axios'
import visual_hero from '../../assets/visual_hero.svg'
import MetaTags from 'react-meta-tags'
import { UserContext } from '../../utils/providers/UserProvider'
import LocationService from '../../utils/services/location'
import {
  LAST_SELECTED_MODE,
  safelyRetrieveStorage,
  SELECTED_ZIP,
} from '../../utils/helpers/localStorageKeys'
import ButtonAddHero from '../../components/ButtonAddHero/ButtonAddHero'
import SelectZipModal from '../../sharedComponents/searchAndFilter/SelectZipModal'
import { menuStore } from '../../stores/menuStore'
import './heroes.less'
import { AppRoutes } from '../../utils/constants/Routing'

export const MODE_LIST = 'list'
export const MODE_MAP = 'map'

const MAX_TIME_ZIP_AVAILABLE = 1000 * 60 * 60 // 24h

const libraries = ['geometry']

///Shortcut
export const Parameters = {
  Country: 'routesHeroesParamsCountry',
  Zip: 'routesHeroesParamsZip',
}

const Heroes = () => {
  const user = useContext(UserContext)
  const [places, setPlaces] = useState([])
  const { keys, localizedReplaceHistory, getLocalizedParams } = textStore()
  // FIXME Do not query the all the places to sort them locally. Use geocoding
  const ref = db.collection('places')
  const { isLoading: placeAreLoading } = useFirestoreQuery(ref, setPlaces)
  const history = useHistory()
  const { isLoaded: mapsLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  })
  const [placeDistances, setPlaceDistances] = useState({})
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [postalCode, setPostalCode] = useState(
    getLocalizedParams({ params: params, paramName: Parameters.Zip })
  )
  const [country, setCountry] = useState(
    getLocalizedParams({ params: params, paramName: Parameters.Country })
  )
  const [city, setCity] = useState(null)
  const [cities, setCities] = useState([])
  const [currentPosition, setCurrentPosition] = useState(null)
  const [locationLoading, setLocationLoading] = useState(true)
  const [subscriptions, setSubscriptions] = useState([])
  const refSubscriptions = db
    .collection('users')
    .doc(user ? user?.uid : 'ignore')
    .collection('subscriptions')
  const { isLoading: subscriptionsAreLoading } = useFirestoreQuery(
    refSubscriptions,
    setSubscriptions,
    !!user
  )

  const { mapMode, setMapMode } = menuStore()
  const [showSelectZipModal, setShowSelectZipModal] = useState(false)

  useEffect(() => {
    checkZipExists()
  }, [postalCode])

  useEffect(() => {
    const localMode = localStorage.getItem(LAST_SELECTED_MODE)
    if (mapMode) {
      setMapMode(localMode)
    }
  }, [])

  const checkZipExists = () => {
    const lsZip = JSON.parse(safelyRetrieveStorage(SELECTED_ZIP))
    const timeNow = new Date().getTime()
    if (
      !postalCode &&
      (!lsZip || timeNow - lsZip.createdAt > MAX_TIME_ZIP_AVAILABLE) &&
      (!user || !user.postcode)
    ) {
      localStorage.removeItem(SELECTED_ZIP)
      setShowSelectZipModal(true)
    } else if (!postalCode && user && user.postcode) {
      setPostalCode(user.postcode)
      setCountry('CH')
    }
  }

  useEffect(() => {
    if (!postalCode || !country) {
      axios
        .get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_API_KEY}`)
        .then(result => {
          setPostalCode(result.data.postal)
          setCountry(result.data.country)
        })
        .catch(() => {
          // API blocked by browser => set default zip
          setPostalCode(3000)
          setCountry('CH')
          checkZipExists()
        })
    }
  }, [postalCode, history])

  useEffect(() => {
    if (!mapsLoaded) return
    if (!postalCode) return
    if (!country) return
    setLocationLoading(true)
    LocationService.getGeocoding(
      postalCode,
      country,
      zipCodeInfos => {
        setCities(zipCodeInfos)
        if (!city || cities.find(cityInfo => cityInfo.city === city)) {
          setCity(zipCodeInfos[0]?.city)
        }
      },
      () => {
        message.error($(keys.invalidNPA))
        localizedReplaceHistory({ path: AppRoutes.Home })
      }
    )
  }, [mapsLoaded, history, postalCode, country])

  useEffect(() => {
    if (city) {
      const currentCity = cities.find(cityInfo => cityInfo.city === city)
      setCurrentPosition({ lat: Number(currentCity?.lat), lng: Number(currentCity?.lng) })
    }
  }, [city])

  useEffect(() => {
    if (!mapsLoaded || !currentPosition) return
    const placeDistances = {}
    places.forEach(place => {
      // Get distance and convert from km to meters
      placeDistances[place.id] =
        LocationService.getCrowDistanceBetweenPoints(currentPosition, place) * 1000
    })
    setPlaceDistances(placeDistances)
    setLocationLoading(false)
  }, [mapsLoaded, currentPosition, places])

  const discoverComponent = () => {
    switch (mapMode) {
      case MODE_MAP:
        return (
          <HeroMap
            isLoading={placeAreLoading || locationLoading || subscriptionsAreLoading}
            mapsLoaded={mapsLoaded}
            currentPosition={currentPosition}
            loadError={loadError}
            places={places}
            subscriptions={subscriptions}
          />
        )
      case MODE_LIST:
      default:
        return (
          <HeroList
            isLoading={placeAreLoading || locationLoading || subscriptionsAreLoading}
            city={city}
            setCity={setCity}
            cities={cities}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            placeDistances={placeDistances}
            places={places}
            subscriptions={subscriptions}
            user={user}
          />
        )
    }
  }

  return (
    <>
      <MetaTags>
        <title>Local Heroes</title>
        <meta name='title' content={$(keys.metaHomeTitle)} />
        <meta name='description' content={$(keys.metaDiscoverDescription)} />
        <meta name='robots' content='Index,follow' />
        <meta property='og:locale' content={keys._interfaceLanguage} />
        <meta property='og:type' content='article' />
        <meta property='og:title' content={$(keys.metaHomeTitle)} />
        <meta property='og:description' content={$(keys.metaDiscoverDescription)} />
        <meta property='og:url' content='https://local-heroes.ch/heroes?country=CH&zip=' />
        <meta property='og:site_name' content='Local Heroes' />
        <meta property='og:image' content={window.location.origin + visual_hero} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={$(keys.metaHomeTitle)} />
        <meta name='twitter:description' content={$(keys.metaDiscoverDescription)} />
        <meta name='twitter:image' content={window.location.origin + visual_hero} />
      </MetaTags>

      {discoverComponent()}

      <ButtonAddHero floating />

      <SelectZipModal showModal={showSelectZipModal} setShowModal={setShowSelectZipModal} />
    </>
  )
}

export default Heroes
