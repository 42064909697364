import React, { useState } from 'react'
import { Button, Checkbox, Col, Form, Input, Row } from 'antd'
import { db } from '../../../api/firebase'
import { $, textStore } from '../../../stores/localization/localization'
import { REGISTERED_NEWSLETTER } from '../../../utils/helpers/localStorageKeys'
import { handleError } from '../../../utils/helpers/handleError'
import './newsletterCard.less'
import { AppRoutes } from '../../../utils/constants/Routing'
import { LocalizedLink } from '../../../utils/localization/LocalizedLink'

const NewsLetterCard = ({ postalCode }) => {
  const [checkedPolicy, setCheckedPolicy] = useState(false)
  const [showSubscribed, setSubscribed] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const { keys } = textStore()

  const onChangePolicy = event => {
    setCheckedPolicy(event.target.checked)
  }

  const handleEmailSubmit = async event => {
    try {
      setButtonLoading(true)
      const payload = {
        email: event.email,
        firstName: event.name,
        postalCode: postalCode,
      }
      // Store the email in firebase
      await db.collection('newsletterSubscription').add(payload)

      // Store cookie to hide newsletter form
      const now = new Date()
      const cookie = {
        expiration: now.getTime() + 1000 * 60 * 60 * 24 * 30, //ms * s * m * h * d
      }
      localStorage.setItem(REGISTERED_NEWSLETTER, JSON.stringify(cookie))
      setSubscribed(true)
    } catch (error) {
      handleError(error)
    } finally {
      setButtonLoading(false)
    }
  }

  return (
    <Col xl={6} lg={8} md={12} sm={24} className='discover-list-item'>
      <div className='newsletter-card px-4'>
        <Row align='left' className='newsletter-card-title pt-4 pb-3'>
          {$(keys.notifyNewsletterTitle)}
        </Row>
        <Form
          name='registerNewsletter'
          style={{ display: showSubscribed ? 'none' : 'block' }}
          layout='vertical'
          colon={false}
          onFinish={handleEmailSubmit}>
          <Form.Item
            className='enter-email'
            name='email'
            rules={[{ required: true }]}
            label={$(keys.emailNewsletter)}>
            <Input name='email' className='input-email' placeholder={$(keys.enterEmailHere)} />
          </Form.Item>
          <Form.Item className='enter-name' name='name' rules={[{ required: true }]}>
            <Input name='name' className='input-name' placeholder={$(keys.enterNameHere)} />
          </Form.Item>
          <Form.Item
            className='checkbox-privacy'
            name='checkedPolicy'
            valuePropName='checked'
            rules={[{ required: true }]}>
            <Checkbox checked={checkedPolicy} onChange={onChangePolicy}>
              {$(
                keys.acceptThe,
                <LocalizedLink to={AppRoutes.PrivacyPolicy}>{$(keys.privacyPolicy)}</LocalizedLink>
              )}
            </Checkbox>
          </Form.Item>
          <Form.Item className='button-register'>
            <Button
              type='primary'
              htmlType='submit'
              loading={buttonLoading}
              disabled={!checkedPolicy}>
              {$(keys.register)}
            </Button>
          </Form.Item>
        </Form>
        <Row
          className='successfully-subscribed mt-5 pt-3'
          style={{ display: showSubscribed ? 'block' : 'none' }}>
          {$(keys.successfullySubscribed)}
        </Row>
      </div>
    </Col>
  )
}

export default NewsLetterCard
