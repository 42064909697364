import React from 'react'
import { Col, Row, Spin } from 'antd'

const LoadingPage = () => {
  return (
    <div
      style={{
        display: 'table-cell',
        textAlign: 'center',
        verticalAlign: 'middle',
        width: '100vw',
        height: '100vh',
      }}>
      <Spin size='large' />
    </div>
  )
}

const Loading = () => {
  return (
    <Row justify='center' align='middle' style={{ size: '100%' }}>
      <Col>
        <Spin size={'large'} />
      </Col>
    </Row>
  )
}

export { Loading }
export default LoadingPage
