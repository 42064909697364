import React from 'react'
import { Card, Carousel, Col, Row, Tooltip } from 'antd'
import RightOutlined from '@ant-design/icons/lib/icons/RightOutlined'
import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import { $, textStore } from '../../../stores/localization/localization'
import PlacePlaceholder from '../../../assets/placeholder.svg'
import storeIcon from '../../../assets/store_grey.svg'
import aideAuxEntLogo from '../../../assets/aide_aux_entreprises.webp'
import deliveryIcon from '../../../assets/truck.svg'
import welqomeLogo from '../../../assets/welqome.webp'
import { changePhotosToThumbnails } from '../../../utils/helpers/checkPictureUrl'
import './heroCard.less'
import { trackGTMEvent } from '../../../utils/helpers/gtmTracking'
import { AppRoutes } from '../../../utils/constants/Routing'

const HeroCard = ({ place, homeCarousel, hideAddress }) => {
  const { keys, localizedPushHistory } = textStore()
  const currentLocation = useLocation()

  const formatPlaceDistance = distanceInMeters => {
    if (!distanceInMeters) {
      return null
    } else if (distanceInMeters > 999) {
      return `${Math.floor(distanceInMeters * 0.001)} km`
    } else {
      return `${Math.floor(distanceInMeters)} m`
    }
  }

  const handleHeroClickTracing = () => {
    let label = 'Visitor-click-heroes-list-click'
    //If we are on the home page we clicked on a featured heroe
    if (currentLocation.pathname === '/') {
      label = 'Visitor-click-home-heroes-featured'
    }
    trackGTMEvent('Visitor', 'Visitor-click', label)
  }

  return (
    <Col key={place.id} xl={6} lg={8} md={12} sm={24} className='hero-card'>
      <Card
        id='card'
        bordered={false}
        className='place-card'
        onClick={handleHeroClickTracing}
        cover={
          <>
            {place?.promotionAideAuxEnt && (
              <img
                className={`promotion-logo-card ${
                  place.promotionWelqome ? 'fit-second-promotion' : ''
                }`}
                src={aideAuxEntLogo}
                alt='Aide aux entreprises logo'
              />
            )}
            {place?.promotionWelqome && (
              <img className='promotion-logo-card' src={welqomeLogo} alt='welQome logo' />
            )}
            {_.isEmpty(place.photos) ? (
              <img
                className='place-photo-card'
                alt={place.name}
                onClick={() =>
                  localizedPushHistory({
                    path: AppRoutes.Hero,
                    vars: { city: place.pathCity, name: place.pathName },
                    state: { previousRoute: currentLocation },
                  })
                }
                src={PlacePlaceholder}
              />
            ) : (
              <Carousel
                arrows={true}
                nextArrow={<RightOutlined />}
                prevArrow={<LeftOutlined />}
                className='hero-carousel'>
                {homeCarousel ? (
                  <img
                    onClick={() =>
                      localizedPushHistory({
                        path: AppRoutes.Hero,
                        vars: { city: place.pathCity, name: place.pathName },
                        state: { previousRoute: currentLocation },
                      })
                    }
                    className='place-photo-card'
                    onError={e => (e.target.src = PlacePlaceholder)}
                    alt={place.name}
                    src={place.photos[0]}
                  />
                ) : (
                  changePhotosToThumbnails(place.photos, 533, 400).map((photo, index) => (
                    <img
                      key={index}
                      onClick={() =>
                        localizedPushHistory({
                          path: AppRoutes.Hero,
                          vars: { city: place.pathCity, name: place.pathName },
                          state: { previousRoute: currentLocation },
                        })
                      }
                      className='place-photo-card'
                      onError={e => (e.target.src = PlacePlaceholder)}
                      alt={place.name}
                      src={photo}
                    />
                  ))
                )}
              </Carousel>
            )}
          </>
        }>
        <div
          onClick={() =>
            localizedPushHistory({
              path: AppRoutes.Hero,
              vars: { city: place.pathCity, name: place.pathName },
              state: { previousRoute: currentLocation },
            })
          }>
          <Row className='card-shop-icon-with-name'>
            <Col>
              <h3 className='card-shop-name'>
                <img src={storeIcon} alt='store' className='icons' />
                {place.name}
              </h3>
            </Col>
          </Row>
          <Row className='card-category'>
            <p>{$(keys[place.category])}</p>
          </Row>
          {!hideAddress && (
            <Row className='card-city-distance-delivery' justify='space-between'>
              <Col className='card-city-distance'>
                <p>{`${place.city}${
                  place.distanceFromMe ? ` • ${formatPlaceDistance(place.distanceFromMe)}` : ''
                }`}</p>
              </Col>
              <Col className='card-delivery'>
                {place.doesDeliver && (
                  <Row align='right' className='mb-2'>
                    <div>
                      <Tooltip placement='top' title={$(keys.homeDelivery)}>
                        <img src={deliveryIcon} alt='delivery' className='card-icon' />
                      </Tooltip>
                    </div>
                  </Row>
                )}
              </Col>
            </Row>
          )}
        </div>
      </Card>
    </Col>
  )
}

export default HeroCard
