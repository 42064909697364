import { db } from './firebase'
import { textStore } from '../stores/localization/localization'
import axios from 'axios'
import { USER_COUNTRY, USER_POSTAL_CODE } from '../utils/helpers/localStorageKeys'

export const fetchUser = async uid => {
  const snapshot = await db.doc(`users/${uid}`).get()
  return snapshot.data()
}

export const fetchSetUserInfo = async (uid, params) => {
  const userRef = db.doc(`users/${uid}`)
  await userRef.set(params, { merge: true })
}

export const fetchSetUserLanguage = async (uid, newLang) => {
  if (!uid || !textStore.getState().keys.getAvailableLanguages().includes(newLang)) return

  const userRef = db.doc(`users/${uid}`)
  await userRef.update({ preferredLanguage: newLang })
}

export const fetchClientIPInfo = async () => {
  try {
    const result = await axios.get(
      `https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_API_KEY}`
    )
    const resultCountry = result?.data?.country ? result?.data?.country : 'CH'
    const resultPostalCode = result?.data?.postal ? result?.data?.postal : ''
    localStorage.setItem(USER_COUNTRY, resultCountry)
    localStorage.setItem(USER_POSTAL_CODE, resultPostalCode)

    return [resultCountry, resultPostalCode]
  } catch (e) {
    console.log(e)
    // Default to Switzerland if error in request (maybe caused by adblocker)
    localStorage.setItem(USER_COUNTRY, 'CH')
    localStorage.setItem(USER_POSTAL_CODE, '')
    return null
  }
}
