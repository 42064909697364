import React, { Suspense, useEffect, useState } from 'react'
import { Route, useLocation } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { Grid, Layout } from 'antd'
import firebase from 'firebase'
import { db } from './api/firebase'
import ScrollToTop from './components/ScrollToTop'
import LoadingPage from './sharedComponents/LoadingPage'
import PageFooter from './sharedComponents/pageFooter/PageFooter'
import { fetchSetUserLanguage } from './api/sessionFetchers'
import { textStore } from './stores/localization/localization'
import UserProvider, { getUserDocument } from './utils/providers/UserProvider'
import { PrivatePlaceRoute, PrivateRoute } from './utils/helpers/PrivateRoute'
import {
  LAST_SELECTED_MODE,
  STORAGE_TEMP_USER_INFO,
  SUBSCRIBE_SIGN_OUT,
} from './utils/helpers/localStorageKeys'
import { ROLE_ADMIN } from './utils/models/User'
import { trackPageView } from './utils/helpers/gtmTracking'
import LocalizedRouter, { LocalizedSwitch } from './utils/localization/LocalizedRouter'
import { menuStore } from './stores/menuStore'
import { MODE_LIST } from './pages/heroes/Heroes'
import PageNavBar from './sharedComponents/navBar/PageNavBar'
import './App.less'
import { AppRoutes } from './utils/constants/Routing'

const Home = React.lazy(() => import('./pages/home/Home'))
const HowDoesItWork = React.lazy(() => import('./components/HowDoesItWork/HowDoesItWorkContainer'))
const Search = React.lazy(() => import('./pages/search/Search'))
const Heroes = React.lazy(() => import('./pages/heroes/Heroes'))
const Products = React.lazy(() => import('./pages/products/Products'))
const Activities = React.lazy(() => import('./pages/activities/Activities'))
const ActivityDetails = React.lazy(() => import('./pages/activityDetails/ActivityDetails'))
const HeroEdit = React.lazy(() => import('./pages/heroEdit/HeroEdit'))
const HeroProfileById = React.lazy(() => import('./pages/heroProfileById/HeroProfileById'))
const HeroEditById = React.lazy(() => import('./pages/heroEditById/HeroEditById'))
const HeroProfile = React.lazy(() => import('./pages/heroProfile/HeroProfile'))
const ProductContactFormContainer = React.lazy(() =>
  import('./components/ProductContactForm/ProductContactFormContainer')
)
const RecoverPasswordComponent = React.lazy(() =>
  import('./components/Login/PasswordLoginComponent')
)
const Welcome = React.lazy(() => import('./pages/welcome/Welcome'))
const MySubscriptionsComponent = React.lazy(() =>
  import('./components/User/MySubscriptionsComponent')
)
const MyBusinesses = React.lazy(() => import('./components/User/MyBusinesses'))
const ManageClaimsComponent = React.lazy(() => import('./components/Admin/ManageClaimsComponent'))
const Manager = React.lazy(() => import('./pages/manager/Manager'))
const HeroCommunicateEdit = React.lazy(() =>
  import('./pages/heroCommunicateEdit/HeroCommunicateEdit')
)
const HeroActivitiesEdit = React.lazy(() => import('./pages/heroActivitiesEdit/HeroActivitiesEdit'))
const HeroProductsEdit = React.lazy(() => import('./pages/heroProductsEdit/HeroProductsEdit'))
const TermsOfUse = React.lazy(() => import('./components/PrivacyPolicy/TermsOfUse'))
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy/PrivacyPolicy'))
const NoMatch = React.lazy(() => import('./components/NoMatch'))

const { useBreakpoint } = Grid

function App() {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const screens = useBreakpoint()

  const { setMapMode } = menuStore()

  const { keys } = textStore()
  let location = useLocation()

  useEffect(() => {
    //avoid issues where the local storage could be null
    const lastSelectedMode = localStorage.getItem(LAST_SELECTED_MODE)
    if (lastSelectedMode === 'null') {
      setMapMode(MODE_LIST)
    }
  }, [setMapMode])

  useEffect(() => {
    trackPageView()
  }, [location])

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async userAuth => {
      if (userAuth) {
        if (!localStorage.getItem(STORAGE_TEMP_USER_INFO)) setLoading(true)

        const userData = await getUserDocument(userAuth)

        if (userData) {
          if (userData.preferredLanguage) {
            keys.setLanguage(userData.preferredLanguage)
          } else {
            userData.preferredLanguage = keys.getLanguage()

            await fetchSetUserLanguage(userData.uid, userData.preferredLanguage)
          }
          await checkSignedOutSubscription(userData)
        }
        setUser(userData)
        setLoading(false)
      } else {
        setLoading(true)
        keys.setLanguage(keys.getInterfaceLanguage())
        setUser(null)
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_GTM != null) {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM,
      })
    }
  }, [])

  const checkSignedOutSubscription = async user => {
    const signedOutSubscribe = localStorage.getItem(SUBSCRIBE_SIGN_OUT)

    if (signedOutSubscribe) {
      const subscriptionsRef = db.collection(`users/${user.uid}/subscriptions`)
      const checkExisting = await subscriptionsRef
        .where('placeId', '==', signedOutSubscribe)
        .limit(1)
        .get()
      if (checkExisting.empty) {
        try {
          await subscriptionsRef.doc().set(
            {
              placeId: signedOutSubscribe,
              createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
            },
            {
              merge: true,
            }
          )
        } catch (error) {
          console.log(error)
        }
      }
      localStorage.removeItem(SUBSCRIBE_SIGN_OUT)
    }
  }

  if (loading) {
    return <LoadingPage />
  }

  const authenticated = user !== null
  const isEmailVerified = user?.emailVerified
  const isAdmin = user?.role === ROLE_ADMIN

  return (
    <LocalizedRouter>
      <Suspense fallback={<LoadingPage />}>
        <UserProvider user={user}>
          <ScrollToTop />
          <Layout className='local-heroes-main-layout'>
            <PageNavBar style={{ marginBottom: screens.lg ? 20 : 64 }} />
            <Layout.Content>
              <LocalizedSwitch>
                <Route exact path={AppRoutes.Home} component={Home} />
                <Route exact path={AppRoutes.HowDoesItWork} component={HowDoesItWork} />
                <Route exact path={AppRoutes.Search} component={Search} />
                <Route exact path={AppRoutes.Heroes} component={Heroes} />
                <Route exact path={AppRoutes.Products} component={Products} />
                <Route exact path={AppRoutes.Activities} component={Activities} />
                <Route exact path={AppRoutes.SwitchActivity} component={ActivityDetails} />
                <Route exact path={AppRoutes.AddHero} component={HeroEdit} />
                <Route exact path={AppRoutes.SwitchHeroById} component={HeroProfileById} />
                <Route exact path={AppRoutes.SwitchEditHeroById} component={HeroEditById} />
                <Route exact path={AppRoutes.SwitchHero} component={HeroProfile} />
                <Route
                  exact
                  path={AppRoutes.SwitchContactProduct}
                  component={ProductContactFormContainer}
                />
                <Route
                  exact
                  path={AppRoutes.RecoverPassword}
                  component={RecoverPasswordComponent}
                />
                <Route exact path={AppRoutes.Welcome} component={Welcome} />
                <PrivateRoute
                  exact
                  path={AppRoutes.Subscriptions}
                  component={MySubscriptionsComponent}
                  authenticated={authenticated}
                  isAuthorized={true}
                  verifiedEmail={isEmailVerified}
                />
                <PrivateRoute
                  exact
                  path={AppRoutes.Businesses}
                  component={MyBusinesses}
                  authenticated={authenticated}
                  isAuthorized={true}
                  verifiedEmail={isEmailVerified}
                />
                <PrivateRoute
                  exact
                  path={AppRoutes.ManageClaims}
                  component={ManageClaimsComponent}
                  authenticated={authenticated}
                  isAuthorized={isAdmin}
                  verifiedEmail={isEmailVerified}
                />
                <PrivateRoute
                  exact
                  path={AppRoutes.Manager}
                  component={Manager}
                  authenticated={authenticated}
                  isAuthorized={isAdmin}
                  verifiedEmail={isEmailVerified}
                />
                <PrivatePlaceRoute
                  exact
                  path={AppRoutes.SwitchEditHero}
                  component={HeroEdit}
                  authenticated={authenticated}
                  userId={user?.uid}
                  isAuthorized={isAdmin}
                  verifiedEmail={isEmailVerified}
                />
                <PrivatePlaceRoute
                  exact
                  path={AppRoutes.SwitchHeroCommunications}
                  component={HeroCommunicateEdit}
                  authenticated={authenticated}
                  userId={user?.uid}
                  isAuthorized={isAdmin}
                  verifiedEmail={isEmailVerified}
                />
                <PrivatePlaceRoute
                  exact
                  path={AppRoutes.SwitchHeroActivities}
                  component={HeroActivitiesEdit}
                  authenticated={authenticated}
                  userId={user?.uid}
                  isAuthorized={isAdmin}
                  verifiedEmail={isEmailVerified}
                />
                <PrivatePlaceRoute
                  exact
                  path={AppRoutes.SwitchHeroProducts}
                  component={HeroProductsEdit}
                  authenticated={authenticated}
                  userId={user?.uid}
                  isAuthorized={isAdmin}
                  verifiedEmail={isEmailVerified}
                />
                <Route exact path={AppRoutes.TermsOfUse} component={TermsOfUse} />
                <Route exact path={AppRoutes.PrivacyPolicy} component={PrivacyPolicy} />
                <Route component={NoMatch} />
              </LocalizedSwitch>
            </Layout.Content>
            <Layout.Footer className='local-heroes-main-footer'>
              <PageFooter />
            </Layout.Footer>
          </Layout>
        </UserProvider>
      </Suspense>
    </LocalizedRouter>
  )
}

export default App
