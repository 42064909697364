import React, { useEffect } from 'react'
import { Redirect, Switch } from 'react-router'
import { BrowserRouter, Route, useHistory, useLocation } from 'react-router-dom'
import { textStore } from '../../stores/localization/localization'
import { getMatchingRouteInLanguage } from './components/routeTranslation'
import Actions from '../../components/Login/CustomEmailActionHandler/Actions'

///Localized route provider with enforcement of the /*lang*/ model.
const LocalizedRouter = ({ children }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { keys, setLanguage } = textStore()

  return (
    <BrowserRouter>
      <Route path='/userManagement' component={Actions} />
      <Route path='/:lang([a-zA-Z]{2})'>
        {({ match }) => {
          if (window.location.pathname.includes('/userManagement')) {
            return <Redirect to={`/userManagement/${window.location.search}`} />
          }

          /**
           * Get current language
           * Set default locale to en if base path is used without a language
           */
          const params = match ? match.params : {}
          let { lang = navigator.language.substr(0, 2) || 'en' } = params
          lang = lang.toLowerCase()

          /**
           * If language is not in route path, redirect to default language.
           */
          if (params.lang === undefined) {
            try {
              const newPath = getMatchingRouteInLanguage(pathname, lang, { history: history })
              return <Redirect to={newPath} />
            } catch (e) {
              return <Redirect to={`/${lang}/`} />
            }
          }

          if (keys.getLanguage() !== lang) {
            setLanguage(lang)
          }

          return <SetupStep children={children} />
        }}
      </Route>
    </BrowserRouter>
  )
}

///Helper component which initializes the textStore variables on first build
const SetupStep = ({ children }) => {
  const history = useHistory()
  const { setup } = textStore()

  useEffect(() => {
    setup(history)
  }, [history, setup])

  return children
}

///The main localization component.
///OBS: It is important for complicated setups that the order which switches are inserted matches the order of the route names in the translation keys.
///If this is not respected, urls which create a conflict in the language switcher will get confused and risk selecting the wrong translated URL.
///An example of this could be "/place/to/{go}" and "/place/{to}/{go}" if the switch and the language keys don't agree on the order, the wrong URL will be selected on switch.
export const LocalizedSwitch = ({ children }) => {
  const { keys, getLanguage } = textStore()

  /**
   * @param path can be string, undefined or string array
   * @returns Localized string path or path array
   */
  function localizeRoutePath(path) {
    switch (typeof path) {
      case 'undefined':
        return undefined
      case 'object':
        return path.map(routeKey => `/${getLanguage()}` + keys.getString(routeKey))
      default:
        return `/${getLanguage()}` + keys.getString(path)
    }
  }

  /**
   * Apply localization to all routes
   * Also checks if all children elements are <Route /> components
   */
  return (
    <Switch>
      {React.Children.map(children, child =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              ...child.props,
              path: localizeRoutePath(child.props.path),
            })
          : child
      )}
    </Switch>
  )
}

export default LocalizedRouter
