import React from 'react'
import { Grid, Space } from 'antd'
import { menuStore } from '../../../stores/menuStore'
import { localizedUrl } from '../../../stores/localization/localization'
import './navigationMenuItem.less'

const { useBreakpoint } = Grid

const NavigationMenuItem = ({ imageActive, imageInactive, text, page }) => {
  const { activePage } = menuStore()
  const screens = useBreakpoint()

  return (
    <Space align='baseline' className='nav__menu-discovery-item'>
      {!screens.xs && (
        <img src={localizedUrl(page) === activePage ? imageActive : imageInactive} alt={text} />
      )}
      {text}
    </Space>
  )
}

export default NavigationMenuItem
