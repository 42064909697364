export const ROLE_ADMIN = 'ADMIN'
export const ROLE_USER = 'USER'

export const USER_ROLES = [ROLE_USER, ROLE_ADMIN]

export class User {
  constructor(json) {
    this.uid = (json && json.uid) || ''
    this.firstName = (json && json.firstName) || ''
    this.displayName = (json && json.displayName) || ''
    this.editedTimestamp = (json && json.editedTimestamp) || null
    this.createdTimestamp = (json && json.createdTimestamp) || null
    this.email = (json && json.email) || ''
    this.emailVerified = (json && json.emailVerified) || false
    this.photoURL = (json && json.photoURL) || ''
    this.phoneNumber = (json && json.phoneNumber) || ''
    this.postcode = (json && json.postcode) || ''
    this.preferredLanguage = (json && json.preferredLanguage) || ''
    this.country = (json && json.country) || ''
    this.acceptTermsPrivacy = (json && json.acceptTermsPrivacy) || false
    this.subscribeNewsletter = (json && json.subscribeNewsletter) || false
    this.role = (json && json.role) || ''
    this.isDeleted = (json && json.isDeleted) || false
  }
}
