import React from 'react'
import { Layout, Result } from 'antd'
import { $, textStore } from '../stores/localization/localization'
import { LocalizedButton } from '../utils/localization/LocalizedButton'
import { AppRoutes } from '../utils/constants/Routing'

const NotAuthorized = () => {
  const { keys } = textStore()
  return (
    <Layout>
      <Layout.Content>
        <div>
          <Result
            status='403'
            title='403'
            subTitle={$(keys.error403)}
            extra={
              <LocalizedButton type='primary' to={AppRoutes.Home}>
                {$(keys.backToDashboard)}
              </LocalizedButton>
            }
          />
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default NotAuthorized
