import React, { useState } from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import { auth } from '../../api/firebase'
import { Link } from 'react-router-dom'
import { $, textStore } from '../../stores/localization/localization'
import './loginDrawer.less'
import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined'
import RecoverPasswordComponent from './RecoverPasswordComponent'
import CreateAccountComponent from './CreateAccountComponent'
import { AppRoutes } from '../../utils/constants/Routing'
import { LocalizedLink } from '../../utils/localization/LocalizedLink'

const PasswordLoginComponent = props => {
  const [emailForLogin, setEmailForLogin] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [recoverPassword, setRecoverPassword] = useState(false)
  const [createAccount, setCreateAccount] = useState(false)
  const { keys } = textStore()

  const validateEmail = async formData => {
    try {
      const methods = await auth.fetchSignInMethodsForEmail(formData.email)
      // If no login methods exist with this email, go to create account page
      if (methods.length === 0) {
        setEmailForLogin(formData.email)
        setCreateAccount(true)
        // If the email already has an account with other method of authentication, redirect to other login
      } else if (methods.indexOf('password') === -1) {
        setErrorMessage()
        props.onRedirectLoginType(methods)
        props.onCancel()
        // Else show regular email/password login
      } else {
        setErrorMessage()
        setEmailForLogin(formData.email)
      }
    } catch (error) {
      console.log(error)
      setErrorMessage($(keys.firebase_signon_errorSignInFailed))
    }
  }

  const attemptLogin = async formData => {
    try {
      const data = await auth.signInWithEmailAndPassword(formData.email, formData.password)
      props.onSuccessfulLogin(data)
      setErrorMessage()
    } catch (error) {
      console.log(error)
      if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
        setErrorMessage($(keys.firebase_signon_errorSignInPassword))
      } else {
        setErrorMessage($(keys.firebase_signon_errorSignInFailed))
      }
    }
  }

  const handleCancelRecover = () => {
    setRecoverPassword(false)
  }

  const handleCancelCreateAccount = () => {
    setEmailForLogin()
    setCreateAccount(false)
  }

  const handleSuccessCreateAccount = () => {
    props.onSuccessfulCreateAccount()
  }

  const cancelPasswordLogin = () => {
    props.onCancel()
  }

  const renderFormCreateAccount = () => {
    return (
      <CreateAccountComponent
        email={emailForLogin}
        onCancelCreateAccount={handleCancelCreateAccount}
        onSuccessCreateAccount={handleSuccessCreateAccount}></CreateAccountComponent>
    )
  }

  const renderFormRecoverPassword = () => {
    return (
      <RecoverPasswordComponent
        email={emailForLogin}
        onCancelRecover={handleCancelRecover}></RecoverPasswordComponent>
    )
  }

  const renderFormLoginEmail = () => {
    return (
      <>
        <LeftOutlined onClick={cancelPasswordLogin} className='floating-login-drawer-back' />
        <Row className='login-drawer-header mb-4' justify='center'>
          <h2>{$(keys.firebase_signon_signInEmail)}</h2>
        </Row>
        <Row align='center' className='password-login pt-3'>
          <Col span={20}>
            {errorMessage && (
              <Row align='center'>
                <Form.Item>
                  <span className='error-message-login'>{errorMessage}</span>
                </Form.Item>
              </Row>
            )}
            <Form
              name='formLoginEmail'
              className='form-login-email'
              layout='vertical'
              hideRequiredMark='true'
              colon='false'
              onFinish={validateEmail}>
              <Form.Item
                name='email'
                validateTrigger='onBlur'
                rules={[
                  {
                    type: 'email',
                    message: `- ${$(keys.firebase_signon_errorInvalidEmail)}`,
                  },
                  {
                    required: true,
                    message: `- ${$(keys.firebase_signon_errorRequiredEmail)}`,
                  },
                ]}>
                <Input
                  name='email'
                  className='input-email'
                  type='email'
                  placeholder={$(keys.firebase_signon_email)}
                />
              </Form.Item>
              <Form.Item>
                <Row justify='end'>
                  <Button
                    type='default'
                    className='input-email-cancel'
                    onClick={cancelPasswordLogin}>
                    {$(keys.firebase_signon_cancel)}
                  </Button>
                  <Button type='primary' className='input-email-next' htmlType='submit'>
                    {$(keys.firebase_signon_next)}
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    )
  }

  const renderFormLogin = () => {
    return (
      <>
        <LeftOutlined onClick={cancelPasswordLogin} className='floating-login-drawer-back' />
        <Row className='login-drawer-header mb-4' justify='center'>
          <h2>{$(keys.firebase_signon_signIn)}</h2>
        </Row>
        <Row align='center' className='password-login pt-3'>
          <Col span={20}>
            {errorMessage && (
              <Row align='center'>
                <Form.Item>
                  <span className='error-message-login'>{errorMessage}</span>
                </Form.Item>
              </Row>
            )}
            <Form
              name='formLogin'
              className='form-login'
              layout='vertical'
              hideRequiredMark='true'
              colon='false'
              onFinish={attemptLogin}
              initialValues={{
                email: emailForLogin,
              }}>
              <Form.Item
                name='email'
                validateTrigger='onBlur'
                rules={[
                  {
                    type: 'email',
                    message: `- ${$(keys.firebase_signon_errorInvalidEmail)}`,
                  },
                  {
                    required: true,
                    message: `- ${$(keys.firebase_signon_errorRequiredEmail)}`,
                  },
                ]}
                label={$(keys.firebase_signon_email)}>
                <Input
                  name='email'
                  className='input-email'
                  type='email'
                  placeholder={$(keys.firebase_signon_email)}
                />
              </Form.Item>
              <Form.Item
                name='password'
                label={$(keys.firebase_signon_password)}
                rules={[
                  {
                    required: true,
                    message: `- ${$(keys.firebase_signon_errorRequiredPassword)}`,
                  },
                ]}>
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Link to='#' onClick={() => setRecoverPassword(true)} rel='noopener noreferrer'>
                  {$(keys.firebase_signon_troubleSigningIn)}
                </Link>
              </Form.Item>
              <Form.Item>
                <Row justify='end'>
                  <Button
                    type='default'
                    className='input-email-cancel'
                    onClick={cancelPasswordLogin}>
                    {$(keys.firebase_signon_cancel)}
                  </Button>
                  <Button type='primary' className='input-email-save' htmlType='submit'>
                    {$(keys.firebase_signon_signIn)}
                  </Button>
                </Row>
              </Form.Item>
              <Form.Item>
                <Row justify='end' className='terms-privacy-footer'>
                  <LocalizedLink
                    to={AppRoutes.TermsOfUse}
                    target='_blank'
                    className='terms-small'
                    rel='noopener noreferrer'>
                    {$(keys.firebase_signon_termsCta)}
                  </LocalizedLink>
                  <LocalizedLink
                    to={AppRoutes.PrivacyPolicy}
                    target='_blank'
                    className='privacy-small'
                    rel='noopener noreferrer'>
                    {$(keys.firebase_signon_privacyCta)}
                  </LocalizedLink>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    )
  }

  if (createAccount) {
    return renderFormCreateAccount()
  }

  if (recoverPassword) {
    return renderFormRecoverPassword()
  }

  // First ask only for the email, to validate it beforehand, and then show full login form (as firebase-ui does)
  if (!emailForLogin) {
    return renderFormLoginEmail()
  } else {
    // Once we have the email, if a user exists, ask for password to try to login
    return renderFormLogin()
  }
}

export default PasswordLoginComponent
