import { db } from './firebase'

export const fetchHero = async id => {
  const hero = await db.collection('places').doc(id).get()
  return hero.data()
}

export const getClaimedHeroesRef = async id => {
  return await db.collection(`users/${id}/claims`).where('isClaimApproved', '==', true).get()
}
