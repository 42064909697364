import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AutoComplete, Button, Col, Modal, Row } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { $, textStore } from '../../stores/localization/localization'
import selectOptionZipcodesCH from '../../utils/helpers/selectOptionZipcodesCH.json'
import {
  safelyRetrieveStorage,
  SELECTED_ZIP,
  USER_COUNTRY,
} from '../../utils/helpers/localStorageKeys'
import LocationService from '../../utils/services/location'
import mapPoint from '../../assets/map_point.svg'
import './selectZipModal.less'
import { Parameters } from '../../pages/heroes/Heroes'
import CloseButton from '../closeButton/CloseButton'
import { AppRoutes } from '../../utils/constants/Routing'

const SelectZipModal = ({ showModal, setShowModal }) => {
  const [postalCodeInput, setPostalCodeInput] = useState('')
  const history = useHistory()
  const { keys, localizedReplaceHistory } = textStore()

  const onPostalCodeChange = () => {
    const zip = postalCodeInput.split(' ')[0]
    localStorage.setItem(
      SELECTED_ZIP,
      JSON.stringify({
        postalCode: zip,
        createdAt: new Date().getTime(),
      })
    )
    localizedReplaceHistory({
      path: AppRoutes.Heroes,
      params: {
        [Parameters.Country]: safelyRetrieveStorage(USER_COUNTRY),
        [Parameters.Zip]: LocationService.extractPostalCode(postalCodeInput),
      },
    })
    history.go(0)
  }

  return (
    <Modal
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}
      zIndex={1006}
      closeIcon={<CloseButton />}
      centered>
      <Row justify='center' className='pt-4'>
        <img src={mapPoint} className='map-point-image' alt='Plant' />
        <h2 className='text-center'>{$(keys.enterPostalCodeModalTitle)}</h2>
        <Row gutter={[16, 16]} className='enter-zip-row find-container'>
          <Col xs={24} md={16}>
            <AutoComplete
              placeholder={$(keys.cityOrZip)}
              value={postalCodeInput}
              className='input-zip-modal'
              onChange={value => {
                setPostalCodeInput(value)
              }}
              filterOption={(inputValue, option) => {
                const cleanInput = inputValue.toString().trim()
                // If it is a number only input, check that it starts with that number
                if (/^\d+$/.test(cleanInput)) {
                  return option.searchable.startsWith(cleanInput)
                  // Else do a normal search to see if it contains the text input
                } else {
                  return (
                    option.searchable.toLowerCase().indexOf(
                      cleanInput
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase()
                    ) !== -1
                  )
                }
              }}>
              {selectOptionZipcodesCH.map((option, index) => {
                return (
                  <AutoComplete.Option
                    key={index}
                    searchable={option.searchText}
                    value={option.label}>
                    {option.label}
                  </AutoComplete.Option>
                )
              })}
            </AutoComplete>
          </Col>
          <Col xs={24} md={8}>
            <Button id='home-find' type='primary' size='large' block onClick={onPostalCodeChange}>
              {$(keys.homeFindBtn)}
              <ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
      </Row>
    </Modal>
  )
}

export default SelectZipModal
