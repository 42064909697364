import React from 'react'
import { Col, Grid, Row, Space } from 'antd'
import { $, textStore } from '../../stores/localization/localization'
import { trackGTMEvent } from '../../utils/helpers/gtmTracking'
import {
  AppRoutes,
  CoteriesFacebook,
  CoteriesInstagram,
  CoteriesWebsite,
  LocalHeroesBlog,
  LocalHeroesMailTo,
} from '../../utils/constants/Routing'
import { LocalizedLink } from '../../utils/localization/LocalizedLink'
import { LanguageSwitcher } from '../../utils/localization/LanguageSwitcher'
import facebookIcon from '../../assets/facebook_grey.svg'
import instagramIcon from '../../assets/instagram_grey.svg'
import './pageFooter.less'

const { useBreakpoint } = Grid

const PageFooter = () => {
  const screens = useBreakpoint()
  const { keys } = textStore()

  const colContacts = (
    <Space direction='vertical' align='start' size={15}>
      <LocalizedLink to={AppRoutes.HowDoesItWork} className='footer-text'>
        {$(keys.howThisWorks)}
      </LocalizedLink>
      <LocalizedLink
        to={AppRoutes.Welcome}
        className='footer-text'
        onClick={() =>
          trackGTMEvent('Heroes', 'Heroes-click', 'Heroes-click-become-heroes-footer')
        }>
        {$(keys.becomeAHeroe)}
      </LocalizedLink>
      <a href={LocalHeroesBlog} target='_blank' className='footer-text'>
        {$(keys.footerBlog)}
      </a>
      <a href={LocalHeroesMailTo} className='footer-text'>
        {$(keys.footerContactUs)}
      </a>
    </Space>
  )

  const colSocialMedia = (
    <Space className='footer-social-media' direction='vertical' align='start' size={15}>
      <a href={CoteriesFacebook} target='_blank' rel='noreferrer' className='footer-text'>
        <img src={facebookIcon} alt='' /> Facebook
      </a>
      <a href={CoteriesInstagram} target='_blank' rel='noreferrer' className='footer-text'>
        <img src={instagramIcon} alt='' /> Instagram
      </a>
    </Space>
  )

  const colLanguageAndConditions = (
    <Space direction='vertical' align='start' size={15}>
      <LanguageSwitcher />
      <LocalizedLink to={AppRoutes.TermsOfUse} className='footer-text'>
        {$(keys.firebase_signon_termsCta)}
      </LocalizedLink>
      <LocalizedLink to={AppRoutes.PrivacyPolicy} className='footer-text'>
        {$(keys.footerPrivacyPolicy)}
      </LocalizedLink>
    </Space>
  )

  return (
    <footer className='footer-container'>
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <Row className='container' gutter={[0, 40]}>
          <Col xs={{ span: 23, offset: 1 }} md={{ span: 6, offset: 0 }}>
            {colContacts}
          </Col>
          {/*Remove on mobile*/}
          <Col xs={{ span: 23, offset: 1 }} md={{ span: 6, offset: 3 }}>
            {colSocialMedia}
          </Col>
          <Col xs={{ span: 23, offset: 1 }} md={{ span: 6, offset: 3 }}>
            {colLanguageAndConditions}
          </Col>
        </Row>
        <Row
          align='middle'
          justify='center'
          className={'container' + screens.md ? '' : 'footer-copyright-xs'}>
          <Col xs={{ offset: 1 }} md={{ offset: 0 }}>
            {$(
              keys.footerCopyright,
              <a href={CoteriesWebsite} target='_blank' rel='noopener noreferrer'>
                {$(keys.coteriesLab)}
              </a>
            )}
          </Col>
        </Row>
      </Space>
    </footer>
  )
}

export default PageFooter
