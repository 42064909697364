import axios from 'axios'
import _ from 'lodash'
import zipCodesCH from '../helpers/zipcodesCH.json'

const LocationService = {
  getGeocoding: async (postalCode, country, callback, errorCallback) => {
    try {
      if (country && country.toLowerCase() === 'ch') {
        const zipCodeInfos =
          !isNaN(postalCode) && zipCodesCH.filter(zip => zip.zip === parseInt(postalCode))
        if (_.isEmpty(zipCodeInfos)) {
          errorCallback()
        } else {
          callback(zipCodeInfos)
        }
      } else {
        const config = {
          params: {
            key: process.env.REACT_APP_LOCATIONIQ_API_KEY,
            q: postalCode,
            countrycodes: country,
            format: 'json',
            addressdetails: 1,
          },
        }
        const result = await axios.get(`https://eu1.locationiq.com/v1/search.php`, config)

        // Filter the values that are important for us
        let zipCodeInfos = result.data.filter(
          place => place.type === 'postcode' && (place.osm_type === 'node' || !place.osm_type)
        )

        if (zipCodeInfos.length < 1) {
          errorCallback()
        }

        zipCodeInfos = zipCodeInfos.map(place => {
          return {
            city:
              place.address?.city ||
              place.address?.town ||
              place.address?.village ||
              place.display_name,
            country: place.address?.country || country,
            lat: place.lat,
            lng: place.lon,
            zip: place.address?.postcode || postalCode,
          }
        })
        // Group by city in a JS Object because of possible duplicates in the API response:
        zipCodeInfos = _.groupBy(zipCodeInfos, 'city')

        // Convert to Array again
        zipCodeInfos = Object.values(zipCodeInfos)

        // Reduce to only one value per city (with preference for the best precision, lat+long with more decimals)
        zipCodeInfos = zipCodeInfos.map(city => {
          return _.maxBy(city, duplicate => {
            return duplicate.lat.toString().length + duplicate.lng.toString().length
          })
        })

        callback(zipCodeInfos)
      }
    } catch (err) {
      errorCallback(err)
    }
  },

  getLatLongBoxFromDistance: (point, distance) => {
    return {
      topLeft: LocationService.getDestinationPointWithBearing(point, distance, -45),
      bottomRight: LocationService.getDestinationPointWithBearing(point, distance, 135),
    }
  },

  getDestinationPointWithBearing: (point, distance, bearing) => {
    const d = distance / 6371 // km
    const rlat = (Number(point.lat) * Math.PI) / 180
    const rlng = (Number(point.lng) * Math.PI) / 180
    const rbearing = (bearing * Math.PI) / 180
    let lat2 = rlat + d * Math.cos(rbearing)
    const dlat = lat2 - rlat
    const dphi = Math.log(Math.tan(lat2 / 2 + Math.PI / 4) / Math.tan(rlat / 2 + Math.PI / 4))
    const q = Math.abs(dlat) > 0.0000000001 ? dlat / dphi : Math.cos(rlat)
    const dlng = (d * Math.sin(rbearing)) / q

    if (Math.abs(lat2) > Math.PI / 2) {
      lat2 = lat2 > 0 ? Math.PI : Math.PI - lat2
    }

    const lng2 = ((rlng + dlng + Math.PI) % (2 * Math.PI)) - Math.PI
    return {
      lat: (lat2 * 180) / Math.PI,
      lng: (lng2 * 180) / Math.PI,
    }
  },

  // Returns the distance in km between 2 lat lng points
  getCrowDistanceBetweenPoints: (point1, point2) => {
    const R = 6371 // km
    const dLat = ((Number(point2.lat) - Number(point1.lat)) * Math.PI) / 180
    const dLon = ((Number(point2.lng) - Number(point1.lng)) * Math.PI) / 180
    const lat1 = (point1.lat * Math.PI) / 180
    const lat2 = (point2.lat * Math.PI) / 180

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return R * c
  },

  extractPostalCode: value => {
    if (!value) {
      return ''
    }
    const numbers = value.trim().match(/(\d+)/g)

    return (numbers && numbers[0]) || ''
  },
}

export default LocationService
