import React, { useState } from 'react'
import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from '@react-google-maps/api'
import { Avatar, Button, Col, Grid, Row } from 'antd'
import { UnorderedListOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { MODE_LIST } from '../Heroes'
import LoadingPage from '../../../sharedComponents/LoadingPage'
import { mapsOptions } from '../../../utils/helpers/mapsOptions'
import { $, textStore } from '../../../stores/localization/localization'
import Logo from '../../../logo.svg'
import '../heroes.less'
import './heroMap.less'
import NavUser from '../../../sharedComponents/navBar/components/NavUser'
import { menuStore } from '../../../stores/menuStore'
import { AppRoutes } from '../../../utils/constants/Routing'
import { LocalizedLink } from '../../../utils/localization/LocalizedLink'

const { useBreakpoint } = Grid

const HeroMap = ({ isLoading, places, loadError, mapsLoaded, currentPosition }) => {
  const [selectedPlace, setSelectedPlace] = useState(null)
  const [infoOpen, setInfoOpen] = useState(false)
  const [mapRef, setMapRef] = useState(null)
  const [markerMap, setMarkerMap] = useState({})

  const { keys, localizedPushHistory } = textStore()
  const { setMapMode } = menuStore()

  const screens = useBreakpoint()

  //Passed to the Google Maps component where regular css won't work.
  const mapContainerStyle = {
    height: '100vh',
    width: '100%',
  }

  const renderMap = () => {
    const bounds = new window.google.maps.LatLngBounds()
    places.forEach(place => {
      const { lat, lng } = place
      const latLng = new window.google.maps.LatLng(lat, lng)
      bounds.extend(latLng)
    })

    const markerClickHandler = place => {
      setSelectedPlace(place)

      if (infoOpen) {
        setInfoOpen(false)
      }

      setInfoOpen(true)
      mapRef.panTo(place)
    }

    const markerLoadHandler = (marker, place) => {
      return setMarkerMap(prevState => {
        return { ...prevState, [place.id]: marker }
      })
    }

    const goToPlaceDetails = place => {
      localizedPushHistory({
        path: AppRoutes.HeroById,
        vars: { id: place.id },
      })
    }

    const clusteringOptions = {
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    }

    return (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        options={mapsOptions}
        onLoad={map => setMapRef(map)}
        initialCenter={currentPosition}
        center={currentPosition}
        zoom={14}>
        <MarkerClusterer options={clusteringOptions}>
          {clustering =>
            places.map((place, i) => (
              <Marker
                key={i}
                position={place}
                onLoad={marker => markerLoadHandler(marker, place)}
                onClick={() => markerClickHandler(place)}
                clusterer={clustering}>
                {infoOpen && selectedPlace.id === place.id && (
                  <InfoWindow anchor={markerMap[place.id]}>
                    <>
                      {place.photos && !_.isEmpty(place.photos) && (
                        <Avatar
                          className='info-window-photo'
                          src={place.photos[0]}
                          alt={place.name}
                          onError={() => false}
                        />
                      )}
                      <h4 className='info-window-text'>{place.name}</h4>

                      <Button type='primary' onClick={() => goToPlaceDetails(place)}>
                        {$(keys.discoverMapPlaceGo)}
                      </Button>
                    </>
                  </InfoWindow>
                )}
              </Marker>
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    )
  }

  if (loadError) {
    return <div>{$(keys.discoverMapError)}</div>
  }

  return (
    <Row className='hero-map'>
      {/*Render Header menu buttons.*/}
      <Col span={24}>
        <Row
          className={
            'header-row ' + (screens && screens.md ? 'desktop-header-row' : 'mobile-header-row')
          }
          justify='space-between'
          align='middle'>
          <Col className='get-pointer-events'>
            <Button className='logo-button'>
              <LocalizedLink to={AppRoutes.Home}>
                <img className='logo-renderer' src={Logo} alt='Local Heroes' />
              </LocalizedLink>
            </Button>
          </Col>
          <Col className='get-pointer-events'>
            <NavUser />
          </Col>
        </Row>
      </Col>
      {/*Keep relative to allow the list button to be absolute.*/}
      <Col span={24} style={{ position: 'relative' }}>
        <Row className='hero-list-button-container' justify='center' align='middle'>
          <Col className='get-pointer-events'>
            <Button type='primary' onClick={() => setMapMode(MODE_LIST)}>
              <UnorderedListOutlined /> Back to list view
            </Button>
          </Col>
        </Row>
        {/*Render map*/}
        {mapsLoaded && !isLoading ? renderMap() : <LoadingPage />}
      </Col>
    </Row>
  )
}

export default HeroMap
