import { localizedUrl } from '../../stores/localization/localization'
import { Button } from 'antd'
import React from 'react'

export const LocalizedButton = props => {
  const { to, params, vars } = props
  const address = localizedUrl(to, params, vars)

  return <Button {...props} href={address} />
}
