import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Dropdown, Grid, Menu, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import useAsyncEffect from 'use-async-effect'
import { MODE_MAP, Parameters as HeroesParams } from '../../../pages/heroes/Heroes'
import { auth, db } from '../../../api/firebase'
import LoginDrawer from '../../../components/Login/LoginDrawer'
import { $, textStore } from '../../../stores/localization/localization'
import { menuStore } from '../../../stores/menuStore'
import { UserContext } from '../../../utils/providers/UserProvider'
import { handleError } from '../../../utils/helpers/handleError'
import { safelyRetrieveStorage, USER_COUNTRY } from '../../../utils/helpers/localStorageKeys'
import { ROLE_ADMIN } from '../../../utils/models/User'
import { trackGTMEvent } from '../../../utils/helpers/gtmTracking'
import { getZipFromStorage } from '../../../utils/zipParameter'
import userIcon from '../../../assets/user.svg'
import hamburgerIcon from '../../../assets/hamburger.svg'
import userCircle from '../../../assets/user-circle.svg'
import './navUser.less'
import { AppRoutes } from '../../../utils/constants/Routing'

const { useBreakpoint } = Grid

const NavUser = () => {
  const { setLoginDrawerVisible, mapMode } = menuStore()

  const [claims, setClaims] = useState([])
  const [isLoading] = useState()
  const { keys, localizedPushHistory } = textStore()
  const user = useContext(UserContext)
  const screens = useBreakpoint()
  const history = useHistory()

  const activePage = menuStore(state => state.activePage)
  const searchParams = new URLSearchParams(history.location.search)
  const getZip = getZipFromStorage(searchParams)

  useAsyncEffect(async isMounted => {
    if (!user) return

    const newClaims = []
    const result = await db
      .collection(`users/${user.uid}/claims`)
      .where('isClaimApproved', '==', true)
      .get()

    result.forEach(data => {
      newClaims.push({
        id: data.id,
        ...data.data(),
      })
    })

    if (!isMounted()) return
    setClaims(newClaims)
  })

  const logout = async () => {
    try {
      await auth.signOut()
    } catch (e) {
      handleError(e)
    }
  }

  const BecomeHeroMenuItem = (
    <Menu.Item
      key={AppRoutes.Welcome}
      onClick={() => {
        trackGTMEvent('Heroes', 'Heroes-click', 'Heroes-click-become-heroes-nav')
        localizedPushHistory({ path: AppRoutes.Welcome })
      }}>
      {$(keys.becomeAHeroe)}
    </Menu.Item>
  )

  const SearchMenuItem = (
    <Menu.Item
      key={AppRoutes.Search}
      onClick={() => {
        trackGTMEvent('Search', 'Search-click', 'Search-click-nav')
        localizedPushHistory({ path: AppRoutes.Search })
      }}>
      {screens.xl ? (
        <Space>
          <SearchOutlined />
          {$(keys.search)}
        </Space>
      ) : screens.md ? (
        <SearchOutlined />
      ) : (
        $(keys.search)
      )}
    </Menu.Item>
  )

  const firstMenuGroup = () => {
    return (
      <>
        <Menu.Item
          key={AppRoutes.Heroes}
          onClick={() =>
            localizedPushHistory({
              path: AppRoutes.Heroes,
              params: {
                [HeroesParams.Country]: safelyRetrieveStorage(USER_COUNTRY),
                [HeroesParams.Zip]: getZip,
              },
            })
          }>
          {$(keys.discover)}
        </Menu.Item>
        <Menu.Item
          key={AppRoutes.HowDoesItWork}
          onClick={() => localizedPushHistory({ path: AppRoutes.HowDoesItWork })}>
          {$(keys.howThisWorks)}
        </Menu.Item>
        <Col md={0}>{BecomeHeroMenuItem}</Col>
        <Col md={0}>{SearchMenuItem}</Col>
        {!user && (
          <>
            <Menu.Divider />
            <Menu.Item key='login' onClick={() => setLoginDrawerVisible(true)}>
              <strong>{$(keys.firebase_signon_signIn)}</strong>
            </Menu.Item>
          </>
        )}
      </>
    )
  }

  const userMenuGroup = () => {
    if (!user) return

    return (
      <>
        <Menu.Divider />
        <Menu.Item key='account'>
          <Space>
            <img src={userIcon} alt='user' className='icons' />
            {user?.email || user?.displayName}
          </Space>
        </Menu.Item>
        <Menu.Item
          key={AppRoutes.Subscriptions}
          onClick={() => localizedPushHistory({ path: AppRoutes.Subscriptions })}>
          {$(keys.mySubscriptions)}
        </Menu.Item>
        {!isLoading && claims?.length > 0 && (
          <Menu.Item
            key={AppRoutes.Businesses}
            onClick={() => localizedPushHistory({ path: AppRoutes.Businesses })}>
            {$(keys.myEstablishments)}
          </Menu.Item>
        )}
      </>
    )
  }

  const adminMenuGroup = () => {
    if (!user || user.role !== ROLE_ADMIN) return

    return (
      <>
        <Menu.Divider />
        <Menu.Item
          key={AppRoutes.ManageClaims}
          onClick={() => localizedPushHistory({ path: AppRoutes.ManageClaims })}>
          {$(keys.claimedEstablishments)}
        </Menu.Item>
        <Menu.Item
          key={AppRoutes.Manager}
          onClick={() => localizedPushHistory({ path: AppRoutes.Manager })}>
          {$(keys.manager)}
        </Menu.Item>
      </>
    )
  }

  const dropDownMenu = (
    <Menu selectedKeys={activePage}>
      {firstMenuGroup()}
      {userMenuGroup()}
      {adminMenuGroup()}
      {user && (
        <>
          <Menu.Divider />
          <Menu.Item key='logout' onClick={logout} className='logout-button'>
            {$(keys.firebase_signon_signOut)}
          </Menu.Item>
        </>
      )}
    </Menu>
  )

  const largeMenuItem = (
    <>
      {SearchMenuItem}
      {BecomeHeroMenuItem}
      <Menu.Divider />
    </>
  )

  return (
    <>
      <Menu
        mode='horizontal'
        className='nav__menu nav__menu-user'
        selectedKeys={activePage}
        disabledOverflow>
        {screens.md && mapMode !== MODE_MAP && largeMenuItem}
        <Dropdown overlay={dropDownMenu} className='nav__menu-dropdown' placement='bottomRight'>
          <Button shape='round'>
            <Space direction='horizontal' size={12}>
              <img src={hamburgerIcon} alt='burger' />
              <img src={userCircle} alt='burger' className='user-icon' />
            </Space>
          </Button>
        </Dropdown>
      </Menu>
      <LoginDrawer />
    </>
  )
}

export default NavUser
