import React, { useContext, useState } from 'react'
import { Button, Col, Drawer, Form, Result, Row } from 'antd'
import { auth } from '../../api/firebase'
import { $, textStore } from '../../stores/localization/localization'
import { UserContext } from '../../utils/providers/UserProvider'
import PasswordLoginComponent from './PasswordLoginComponent'
import PhoneLoginComponent from './PhoneLoginComponent'
import firebase from 'firebase/app'
import './loginDrawer.less'
import VerificationSentComponent from './VerificationSentComponent'
import { handleError } from '../../utils/helpers/handleError'
import { trackGTMEvent } from '../../utils/helpers/gtmTracking'
import { getClaimedHeroesRef } from '../../api/heroFetchers'
import { menuStore } from '../../stores/menuStore'
import { LocalizedLink } from '../../utils/localization/LocalizedLink'
import { AppRoutes } from '../../utils/constants/Routing'

const ENABLED_LOGIN_TYPES = {
  google: true,
  facebook: false,
  twitter: false,
  github: false,
  phone: false,
  password: true,
}

const LoginDrawer = () => {
  const { loginDrawerVisible, setLoginDrawerVisible } = menuStore()

  const [selectedLoginType, setSelectedLoginType] = useState()
  const [reloadOnClose, setReloadOnClose] = useState()
  const user = useContext(UserContext)
  const [errorMessage, setErrorMessage] = useState()
  const [sentVerificationEmail, setSentVerificationEmail] = useState()
  // List of enabled login types, set their value false to hide any of them.
  const [enabledLoginTypes, setEnabledLoginTypes] = useState(ENABLED_LOGIN_TYPES)
  const { keys } = textStore()

  async function trackUserLogin(userData) {
    const places = await getClaimedHeroesRef(userData.user.id)
    if (places?.size > 0) {
      trackGTMEvent('Heroes', 'Heroes-login')
    } else {
      trackGTMEvent('Visitor', 'Visitor-login')
    }
  }

  const onCloseDrawer = () => {
    setLoginDrawerVisible(false)
    setEnabledLoginTypes(ENABLED_LOGIN_TYPES)
    setSentVerificationEmail(false)
    setErrorMessage()
    if (reloadOnClose) {
      setReloadOnClose(false)
      window.location.reload(false)
    }
  }

  const handleCanceledLogin = () => {
    setSelectedLoginType()
  }

  const handleSuccessfulLogin = async data => {
    await trackUserLogin(data)

    setSelectedLoginType()
    onCloseDrawer()
  }

  const handleSuccessfulCreateAccount = () => {
    trackGTMEvent('General', 'General-create-account')
    setSelectedLoginType()
    setSentVerificationEmail(true)
    setReloadOnClose(true)
  }

  const handleRedirectLoginType = loginTypes => {
    setErrorMessage($(keys.firebase_signon_errorSignInExistingAccount))
    setEnabledLoginTypes({
      google: loginTypes.indexOf('google.com') !== -1,
      facebook: loginTypes.indexOf('facebook.com') !== -1,
      twitter: loginTypes.indexOf('twitter.com') !== -1,
      github: loginTypes.indexOf('github.com') !== -1,
      phone: loginTypes.indexOf('phone') !== -1,
      password: loginTypes.indexOf('password') !== -1,
    })
  }

  const displayVerifyEmailForm = () => {
    if (user?.emailVerified) {
      onCloseDrawer()
    } else {
      if (sentVerificationEmail) {
        return <VerificationSentComponent onBack={() => setSentVerificationEmail(false)} />
      } else {
        return (
          <>
            <Row align='center' className=''>
              <Col span={20}>
                <Result
                  title={$(keys.pleaseVerifyYourEmail)}
                  extra={
                    <Button type='primary' onClick={() => sendVerificationEmail()}>
                      {$(keys.firebase_signon_verifyYourEmailSendAgain)}
                    </Button>
                  }
                />
              </Col>
            </Row>
            <Row align='center'>
              <Button type='primary' onClick={logout}>
                {$(keys.firebase_signon_signOut)}
              </Button>
            </Row>
          </>
        )
      }
    }
  }

  const logout = async () => {
    try {
      await auth.signOut()
    } catch (e) {
      handleError(e)
    }
  }

  const sendVerificationEmail = async () => {
    await auth.currentUser.sendEmailVerification()
    setSentVerificationEmail(true)
  }

  const handleLoginType = async type => {
    switch (type) {
      case 'google':
        try {
          const data = await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
          await handleSuccessfulLogin(data)
        } catch (error) {
          console.log(error)
          setErrorMessage($(keys.firebase_signon_errorSignInFailed))
        }
        break
      case 'facebook':
        try {
          const data = await auth.signInWithPopup(new firebase.auth.FacebookAuthProvider())
          await handleSuccessfulLogin(data)
        } catch (error) {
          console.log(error)
          setErrorMessage($(keys.firebase_signon_errorSignInFailed))
        }
        break
      case 'twitter':
        try {
          const data = await auth.signInWithPopup(new firebase.auth.TwitterAuthProvider())
          await handleSuccessfulLogin(data)
        } catch (error) {
          console.log(error)
          setErrorMessage($(keys.firebase_signon_errorSignInFailed))
        }
        break
      case 'github':
        try {
          const data = await auth.signInWithPopup(new firebase.auth.GithubAuthProvider())
          await handleSuccessfulLogin(data)
        } catch (error) {
          console.log(error)
          setErrorMessage($(keys.firebase_signon_errorSignInFailed))
        }
        break
      case 'phone':
        setSelectedLoginType('phone')
        break
      case 'password':
      default:
        setSelectedLoginType('password')
        break
    }
  }

  const renderChooseLogin = () => {
    return (
      <div className=''>
        <Row className='login-drawer-header mb-4' justify='center'>
          <h2>{$(keys.firebase_signon_signIn)}</h2>
        </Row>
        <Row className='px-3 pb-5'>
          <Col span='24' align='left'>
            {$(keys.signInDescription)}
          </Col>
        </Row>
        <Row align='center'>
          <Col span={20} align='center'>
            {errorMessage && (
              <Row align='center'>
                <Form.Item>
                  <span className='error-message-login'>{errorMessage}</span>
                </Form.Item>
              </Row>
            )}
            <Form name='chooseLogin' layout='vertical'>
              {enabledLoginTypes.google && (
                <Form.Item>
                  <Button
                    type='default'
                    size='large'
                    className='sign-in-button sign-in-google'
                    onClick={() => handleLoginType('google')}>
                    <span role='img' className='anticon'>
                      <img
                        className='sign-in-button-icon'
                        alt='Google sign in'
                        src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg'
                      />
                    </span>
                    {$(keys.firebase_signon_signInGoogle)}
                  </Button>
                </Form.Item>
              )}
              {enabledLoginTypes.facebook && (
                <Form.Item>
                  <Button
                    type='default'
                    size='large'
                    className='sign-in-button sign-in-facebook'
                    onClick={() => handleLoginType('facebook')}>
                    <span role='img' className='anticon'>
                      <img
                        className='sign-in-button-icon'
                        alt='Facebook sign in'
                        src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg'
                      />
                    </span>
                    {$(keys.firebase_signon_signInFacebook)}
                  </Button>
                </Form.Item>
              )}
              {enabledLoginTypes.twitter && (
                <Form.Item>
                  <Button
                    type='default'
                    size='large'
                    className='sign-in-button sign-in-twitter'
                    onClick={() => handleLoginType('twitter')}>
                    <span role='img' className='anticon'>
                      <img
                        className='sign-in-button-icon'
                        alt='Twitter sign in'
                        src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/twitter.svg'
                      />
                    </span>
                    {$(keys.firebase_signon_signInTwitter)}
                  </Button>
                </Form.Item>
              )}
              {enabledLoginTypes.github && (
                <Form.Item>
                  <Button
                    type='default'
                    size='large'
                    className='sign-in-button sign-in-github'
                    onClick={() => handleLoginType('github')}>
                    <span role='img' className='anticon'>
                      <img
                        className='sign-in-button-icon'
                        alt='Github sign in'
                        src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/github.svg'
                      />
                    </span>
                    {$(keys.firebase_signon_signInGitHub)}
                  </Button>
                </Form.Item>
              )}
              {enabledLoginTypes.phone && (
                <Form.Item>
                  <Button
                    type='default'
                    size='large'
                    className='sign-in-button sign-in-phone'
                    onClick={() => handleLoginType('phone')}>
                    <span role='img' className='anticon'>
                      <img
                        className='sign-in-button-icon'
                        alt='Phone sign in'
                        src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/phone.svg'
                      />
                    </span>
                    {$(keys.firebase_signon_signInPhone)}
                  </Button>
                </Form.Item>
              )}
              {enabledLoginTypes.password && (
                <Form.Item>
                  <Button
                    type='default'
                    size='large'
                    className='sign-in-button sign-in-password'
                    onClick={() => handleLoginType('password')}>
                    <span role='img' className='anticon'>
                      <img
                        className='sign-in-button-icon'
                        alt='Password sign in'
                        src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg'
                      />
                    </span>
                    {$(keys.firebase_signon_signInEmail)}
                  </Button>
                </Form.Item>
              )}
            </Form>
          </Col>
        </Row>
        <Row className='mt-4 p-3'>
          <Col span='24' align='left'>
            {$(
              keys.firebase_signon_byCotinuingTermsPriv,
              <LocalizedLink
                className='terms-link'
                to={AppRoutes.TermsOfUse}
                target='_blank'
                rel='noopener noreferrer'>
                {$(keys.firebase_signon_termsCta)}
              </LocalizedLink>,
              <LocalizedLink
                className='privacy-link'
                to={AppRoutes.PrivacyPolicy}
                target='_blank'
                rel='noopener noreferrer'>
                {$(keys.firebase_signon_privacyCta)}
              </LocalizedLink>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  const renderPasswordLogin = () => {
    return (
      <PasswordLoginComponent
        onCancel={handleCanceledLogin}
        onSuccessfulLogin={handleSuccessfulLogin}
        onSuccessfulCreateAccount={handleSuccessfulCreateAccount}
        onRedirectLoginType={handleRedirectLoginType}
      />
    )
  }

  const renderPhoneLogin = () => {
    return (
      <PhoneLoginComponent
        onCancel={handleCanceledLogin}
        onSuccessfulLogin={handleSuccessfulLogin}
      />
    )
  }

  const displayLoginForm = () => {
    switch (selectedLoginType) {
      case 'password':
        return renderPasswordLogin()
      case 'phone':
        return renderPhoneLogin()
      default:
        return renderChooseLogin()
    }
  }

  return (
    <Drawer
      className='login-drawer'
      placement='right'
      closable={true}
      onClose={onCloseDrawer}
      visible={loginDrawerVisible}>
      {user && !user.emailVerified ? displayVerifyEmailForm() : displayLoginForm()}
    </Drawer>
  )
}

export default LoginDrawer
