import { localizedUrl } from '../../stores/localization/localization'
import { Link } from 'react-router-dom'
import React from 'react'

export const LocalizedLink = props => {
  const { to, params, vars } = props
  let address = localizedUrl(to, params, vars)

  if (to === 'routesHeroSwitchRoute') {
    address = address.replace(':city', vars[0]).replace(':name', vars[1])
  }

  return <Link {...props} to={address} />
}
