import { Row } from 'antd'
import logo_vaudoise from '../../assets/Logo_Vaudoise_Assurances.svg'
import React from 'react'

import './logoVaudoise.less'

const LogoVaudoise = ({ text }) => {
  return (
    <Row align='middle' justify='center' className='vaudoise-logo-container'>
      {text}
      <a type='link' href='https://www.vaudoise.ch/' target='_blank' rel='noopener noreferrer'>
        <img src={logo_vaudoise} alt='Vaudoise' />
      </a>
    </Row>
  )
}

export default LogoVaudoise
