import React, { useState } from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import { auth } from '../../api/firebase'
import firebase from 'firebase/app'
import { $, textStore } from '../../stores/localization/localization'
import './loginDrawer.less'
import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined'
import { LocalizedLink } from '../../utils/localization/LocalizedLink'
import { AppRoutes } from '../../utils/constants/Routing'

const PhoneLoginComponent = props => {
  const [errorMessage, setErrorMessage] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [verificationId, setVerificationId] = useState()
  const { keys } = textStore()

  const sendVerificationCode = async formData => {
    try {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('verify', {
        size: 'invisible',
        callback: function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log(response)
        },
      })
      const appVerifier = window.recaptchaVerifier
      const result = await auth.signInWithPhoneNumber(formData.phoneNumber, appVerifier)
      setPhoneNumber(formData.phoneNumber)
      setVerificationId(result)
      setErrorMessage()
    } catch (error) {
      console.log(error)
      setErrorMessage($(keys.firebase_signon_errorSignInFailed))
    }
  }

  const attemptLogin = async formData => {
    try {
      const data = await verificationId.confirm(formData.code)
      props.onSuccessfulLogin(data)
      setErrorMessage()
    } catch (error) {
      console.log(error)
      if (error.code === 'auth/invalid-verification-code') {
        setErrorMessage($(keys.firebase_signon_errorWrongCode))
      } else {
        setErrorMessage($(keys.firebase_signon_errorSignInFailed))
      }
    }
  }

  const cancelPhoneLogin = () => {
    props.onCancel()
  }

  const renderFormEnterPhone = () => {
    return (
      <>
        <LeftOutlined onClick={cancelPhoneLogin} className='floating-login-drawer-back' />
        <Row className='login-drawer-header mb-4' justify='center'>
          <h2>{$(keys.firebase_signon_verifyPhoneTitle)}</h2>
        </Row>
        <Row align='center' className='phone-login login-body'>
          <Col span={20}>
            {errorMessage && (
              <Row align='center'>
                <Form.Item>
                  <span className='error-message-login'>{errorMessage}</span>
                </Form.Item>
              </Row>
            )}
            <Form
              name='formEnterPhone'
              className='form-login-phone'
              layout='vertical'
              hideRequiredMark='true'
              colon='false'
              onFinish={sendVerificationCode}
              initialValues={{
                phoneNumber: '+',
              }}>
              <Form.Item
                name='phoneNumber'
                label={$(keys.firebase_signon_phoneNumber)}
                rules={[
                  {
                    validator: (_, value) =>
                      new RegExp(
                        '(?=^[\\s\\*\\.\\-\\(\\)\\+\\d]*$)(?=^[^\\+]*\\+[^\\+]*$)(?:(?=[\\s\\(]*\\+.*\\d.{6,}))'
                      ).test(value)
                        ? Promise.resolve()
                        : Promise.reject(`- ${$(keys.firebase_signon_errorValidPhoneNumber)}`),
                  },
                ]}>
                <Input
                  name='phoneNumber'
                  className='input-phone-number'
                  type='tel'
                  placeholder={$(keys.firebase_signon_phoneNumber)}
                />
              </Form.Item>
              <Form.Item>
                <Row justify='end'>
                  <Button type='default' className='input-phone-cancel' onClick={cancelPhoneLogin}>
                    {$(keys.firebase_signon_cancel)}
                  </Button>
                  <Button
                    id='verify'
                    type='primary'
                    className='input-phone-verify'
                    htmlType='submit'>
                    {$(keys.firebase_signon_verify)}
                  </Button>
                </Row>
              </Form.Item>
              <Form.Item>
                <Row className='disclaimer-phone-footer'>
                  {$(keys.firebase_signon_loginPhoneDisclaimer)}
                </Row>
              </Form.Item>
              <Form.Item>
                <Row justify='end' className='terms-privacy-footer'>
                  <LocalizedLink
                    to={AppRoutes.TermsOfUse}
                    target='_blank'
                    className='terms-small'
                    rel='noopener noreferrer'>
                    {$(keys.firebase_signon_termsCta)}
                  </LocalizedLink>
                  <LocalizedLink
                    to={AppRoutes.PrivacyPolicy}
                    target='_blank'
                    className='privacy-small'
                    rel='noopener noreferrer'>
                    {$(keys.firebase_signon_privacyCta)}
                  </LocalizedLink>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    )
  }

  const renderFormVerifyPhone = () => {
    return (
      <>
        <LeftOutlined onClick={cancelPhoneLogin} className='floating-login-drawer-back' />
        <Row className='login-drawer-header mb-4' justify='center'>
          <h2>{$(keys.firebase_signon_verifyPhoneTitle)}</h2>
        </Row>
        <Row align='center' className='phone-login login-body'>
          <Col span={20}>
            <div>{$(keys.firebase_signon_verifyPhoneDescription, phoneNumber)}</div>
            {errorMessage && (
              <Row align='center'>
                <Form.Item>
                  <span className='error-message-login'>{errorMessage}</span>
                </Form.Item>
              </Row>
            )}
            <Form
              name='formLoginPhone'
              className='form-login-phone'
              layout='vertical'
              hideRequiredMark='true'
              colon='false'
              onFinish={attemptLogin}>
              <Form.Item
                name='code'
                rules={[
                  {
                    validator: (_, value) =>
                      value && value.length === 6
                        ? Promise.resolve()
                        : Promise.reject(`- ${$(keys.firebase_signon_errorCodeLength)}`),
                  },
                ]}
                label={$(keys.firebase_signon_sixDigitCode)}>
                <Input
                  name='code'
                  className='input-code'
                  placeholder={$(keys.firebase_signon_sixDigitCode)}
                />
              </Form.Item>
              <Form.Item>
                <Row justify='end'>
                  <Button
                    type='default'
                    className='input-email-cancel'
                    onClick={() => {
                      setPhoneNumber()
                      setVerificationId()
                      cancelPhoneLogin()
                    }}>
                    {$(keys.firebase_signon_cancel)}
                  </Button>
                  <Button type='primary' className='input-phone-continue' htmlType='submit'>
                    {$(keys.firebase_signon_continue)}
                  </Button>
                </Row>
              </Form.Item>
              <Form.Item>
                <Row justify='end' className='terms-privacy-footer'>
                  <LocalizedLink
                    to={AppRoutes.TermsOfUse}
                    target='_blank'
                    className='terms-small'
                    rel='noopener noreferrer'>
                    {$(keys.firebase_signon_termsCta)}
                  </LocalizedLink>
                  <LocalizedLink
                    to={AppRoutes.PrivacyPolicy}
                    target='_blank'
                    className='privacy-small'
                    rel='noopener noreferrer'>
                    {$(keys.firebase_signon_privacyCta)}
                  </LocalizedLink>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    )
  }

  // First ask only for the phone number, to validate it
  if (!phoneNumber) {
    return renderFormEnterPhone()
  } else {
    // Once we have the phone number, ask for verification code sent to the phone
    return renderFormVerifyPhone()
  }
}

export default PhoneLoginComponent
