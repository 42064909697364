import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Col, Grid, Row } from 'antd'
import NavDiscovery from './components/NavDiscovery'
import NavUser from './components/NavUser'
import { menuStore } from '../../stores/menuStore'
import { localizedUrl } from '../../stores/localization/localization'
import logo from '../../logo.svg'
import './navHeader.less'
import { AppRoutes } from '../../utils/constants/Routing'
import { LocalizedLink } from '../../utils/localization/LocalizedLink'

const { useBreakpoint } = Grid

const NavHeader = () => {
  const location = useLocation()
  const screens = useBreakpoint()

  const { setActivePage } = menuStore()

  function getMenuPages() {
    const menuPages = [
      AppRoutes.Heroes,
      AppRoutes.Products,
      AppRoutes.Activities,
      AppRoutes.Search,
      AppRoutes.Welcome,
    ]

    return menuPages.map(x => {
      return localizedUrl(x)
    })
  }

  useEffect(() => {
    const menuPages = getMenuPages()

    const active = menuPages.find(item => {
      return location.pathname.startsWith(item)
    })
    setActivePage(active || null)
  }, [location.pathname, setActivePage])

  const endColumns = () => {
    if (screens.lg) {
      return (
        <>
          <Col className='nav__menu-col'>
            <NavDiscovery />
          </Col>
          <Col className='nav__menu-col'>
            <NavUser />
          </Col>
        </>
      )
    } else {
      return (
        <>
          <Col className='nav__menu-col'>
            <NavUser />
          </Col>
          <Col className='nav__menu-col' span={24}>
            <Row justify='center'>
              <NavDiscovery />
            </Row>
          </Col>
        </>
      )
    }
  }

  return (
    <Row justify='center' className='nav-header-container'>
      <Col xl={18} xs={23}>
        <Row justify='space-between'>
          <Col className='nav__menu-col'>
            <LocalizedLink to={AppRoutes.Home}>
              <img className='header-logo' src={logo} alt='LocalHeroes' />
            </LocalizedLink>
          </Col>
          {endColumns()}
        </Row>
      </Col>
    </Row>
  )
}

export default NavHeader
