import React from 'react'
import { keys, textStore } from '../../../stores/localization/localization'
import { AppRoutes } from '../../constants/Routing'

///OBS: It is important for complicated setups that the order which switches are inserted matches the order of the route names in the translation keys.
///If this is not respected, urls which create a conflict in the language switcher will get confused and risk selecting the wrong translated URL.
///An example of this could be "/place/to/{go}" and "/place/{to}/{go}" if the switch and the language keys don't agree on the order, the wrong URL will be selected on switch.

const urlContainsLanguage = path => {
  return keys.getAvailableLanguages().includes(path.substring(1, 3))
}

/// Create a route to match the current in the inputted language.
export const getMatchingRouteInLanguage = (pathName, newLang, { history }) => {
  const pathHasURL = urlContainsLanguage(pathName)
  // Get the key of the route the user is currently on
  const currLanguage = pathHasURL ? pathName.substring(1, 3) : textStore.getState().getLanguage()
  const currRoute = pathHasURL ? pathName.substring(3) : pathName // remove local part '/en' from the pathname /en/contact
  const currRouteParts = currRoute.split('/')
  const key = textStore.getState().keys
  let currVariables = []

  //Find out which language the path is currently in.
  let availableLanguages = key.getAvailableLanguages()
  //Check current language first.
  availableLanguages.filter(item => item === currLanguage)
  availableLanguages = [currLanguage, ...availableLanguages]

  let newRouteKey = ''
  const oldLang = availableLanguages.find(lang => {
    //Find out which url key we're currently using.
    const route = Object.values(AppRoutes).find(routeKey => {
      const newRoute = key.getString(routeKey, lang)
      //1. Check that there are no variables in the url. Otherwise, look normally
      if (!newRoute.includes('{') && !newRoute.includes(':')) {
        return currRoute === newRoute
      }

      //2. get the variable components.
      const newRouteParts = newRoute.split('/')
      if (newRouteParts.length !== currRouteParts.length) {
        return false
      }

      const variableCache = []

      for (let i = newRouteParts.length - 1; i >= 0; i--) {
        if (newRouteParts[i].startsWith('{') || newRouteParts[i].startsWith(':')) {
          variableCache.push(currRouteParts[i])
        } else {
          if (newRouteParts[i] !== currRouteParts[i]) {
            return false
          }
        }
      }

      currVariables = variableCache
      return true
    })

    if (route !== undefined) {
      newRouteKey = route
      return true
    }

    return false
  })

  //Get the new url
  let matchingRoute = key.getString(newRouteKey, newLang)

  if (matchingRoute.includes('{') || matchingRoute.includes(':')) {
    // Construct the matching route for the new language
    let newRouteParts = matchingRoute.split('/')

    matchingRoute = newRouteParts[0]
    newRouteParts = newRouteParts.filter((x, i) => i !== 0)

    newRouteParts.forEach(x => {
      if (x.startsWith('{') || x.startsWith(':')) {
        matchingRoute += `/${currVariables.pop()}`
      } else {
        matchingRoute += `/${x}`
      }
    })
  }

  //Translate url parameters
  const params = getMatchingParamsInLanguage(oldLang, newLang, {
    routeKey: newRouteKey,
    history: history,
  })

  // Return localized route
  return `/${newLang}` + matchingRoute + params
}

/// Find matching parameters for the selected language for the currently registered parameters.
export const getMatchingParamsInLanguage = (oldLang, newLang, { routeKey, history }) => {
  const key = textStore.getState().keys
  const searchParams = new URLSearchParams(history.location.search)
  const routeVars = Object.keys(key).filter(
    value => value.startsWith('routes') && value.includes(routeKey)
  )
  let newParams = '?'

  let count = 0
  searchParams.forEach((value, key2) => {
    count++
    for (let i = routeVars.length - 1; i >= 0; i--) {
      const str = key.getString(routeVars[i], oldLang)

      if (str.includes(key2)) {
        newParams += `${key.getString(routeVars[i], newLang)}=${value}&`
        break
      }
    }
  })

  if (count === 0) {
    return ''
  }

  return newParams.substring(0, newParams.length - 1)
}
