import React, { useState } from 'react'
import { $, textStore } from '../../stores/localization/localization'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row, Tooltip } from 'antd'
import marketPlace1 from '../../assets/market_place_1.webp'
import marketPlace2 from '../../assets/market_place_2.webp'
import './buttonAddHero.less'
import plusWhite from '../../assets/plus_white.svg'
import { trackGTMEvent } from '../../utils/helpers/gtmTracking'
import CloseButton from '../../sharedComponents/closeButton/CloseButton'
import { AppRoutes } from '../../utils/constants/Routing'

const ButtonAddHero = ({ fullWidth, floating }) => {
  const [showModal, setShowModal] = useState(false)
  const { keys, localizedPushHistory } = textStore()

  const triggerShowModal = () => {
    trackGTMEvent('Visitor', 'Visitor-click', 'Visitor-click-heroes-list-heroes-plus')
    setShowModal(true)
  }

  return (
    <>
      {floating ? (
        <Tooltip placement='top' title={keys.homeAddHeroBtn}>
          <Button
            type='primary'
            className='add-button'
            shape='round'
            icon={<PlusOutlined style={{ fontSize: 24 }} />}
            onClick={() => triggerShowModal()}
          />
        </Tooltip>
      ) : (
        <Button
          size='large'
          type='primary'
          onClick={() => triggerShowModal()}
          className={fullWidth ? 'btn-block-until-md' : ''}>
          <img className='button-icon' src={plusWhite} alt={$(keys.homeAddHeroBtn)} />{' '}
          {$(keys.homeAddHeroBtn)}
        </Button>
      )}
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width={877}
        zIndex={9999}
        closeIcon={<CloseButton />}
        centered>
        <Row justify='center' className='modal-content my-5'>
          <Col span={24} className='mb-3'>
            <h1>{$(keys.addHeroModalTitle)}</h1>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12}>
                <h2>{$(keys.addHeroModalYouAreHero)}</h2>
              </Col>
              <Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12}>
                <h2>{$(keys.addHeroModalKnowsHero)}</h2>
              </Col>
              <Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12}>
                <img src={marketPlace2} alt='marketPlace2' />
              </Col>
              <Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12}>
                <img src={marketPlace1} alt='marketPlace1' />
              </Col>
              <Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12}>
                <Button
                  size='large'
                  type='primary'
                  onClick={() => localizedPushHistory({ path: AppRoutes.Welcome })}
                  className='btn-block-until-md'>
                  {$(keys.createProfileButton)}
                  <PlusOutlined />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12}>
                <Button
                  size='large'
                  type='primary'
                  onClick={() =>
                    localizedPushHistory({
                      path: AppRoutes.AddHero,
                    })
                  }
                  className='btn-block-until-md'>
                  {$(keys.homeAddHeroBtn)}
                  <PlusOutlined />
                </Button>
              </Col>
              <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                <h2>{$(keys.addHeroModalYouAreHero)}</h2>
              </Col>
              <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                <img src={marketPlace2} alt='marketPlace2' />
              </Col>
              <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                <Button
                  size='large'
                  type='primary'
                  onClick={() => localizedPushHistory({ path: AppRoutes.Welcome })}
                  className='btn-block-until-md mb-3'>
                  {$(keys.createProfileButton)}
                  <PlusOutlined />
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
            <Row gutter={[16, 16]} justify='center'>
              <Col span={24}>
                <h2>{$(keys.addHeroModalKnowsHero)}</h2>
              </Col>
              <Col span={24}>
                <img src={marketPlace1} alt='marketPlace1' />
              </Col>
              <Col span={24}>
                <Button
                  size='large'
                  type='primary'
                  onClick={() =>
                    localizedPushHistory({
                      path: AppRoutes.AddHero,
                    })
                  }
                  className='btn-block-until-md'>
                  {$(keys.homeAddHeroBtn)}
                  <PlusOutlined />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default ButtonAddHero
