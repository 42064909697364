import React, { useState } from 'react'
import { Button, Checkbox, Col, Form, Input, Radio, Row } from 'antd'
import { auth } from '../../api/firebase'
import { $, textStore } from '../../stores/localization/localization'
import { ROLE_USER } from '../../utils/models/User'
import { STORAGE_TEMP_USER_INFO } from '../../utils/helpers/localStorageKeys'
import { useForceUpdate } from '../../utils/hooks/forceUpdate'
import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined'
import './loginDrawer.less'
import { AppRoutes } from '../../utils/constants/Routing'
import { LocalizedLink } from '../../utils/localization/LocalizedLink'

const CreateAccountComponent = props => {
  const forceUpdate = useForceUpdate()
  const [errorMessage, setErrorMessage] = useState()
  const { keys } = textStore()
  const [languagesList] = useState(keys.getAvailableLanguages())

  const createAccount = async formData => {
    try {
      localStorage.setItem(
        STORAGE_TEMP_USER_INFO,
        JSON.stringify({
          firstName: formData.firstName,
          postcode: formData.postcode,
          country: 'CH',
          acceptTermsPrivacy: !!formData.acceptTermsPrivacy, // When undefined or null, convert to boolean
          subscribeNewsletter: !!formData.subscribeNewsletter, // When undefined or null, convert to boolean
          role: ROLE_USER,
          isDeleted: false,
        })
      )
      const { user } = await auth.createUserWithEmailAndPassword(formData.email, formData.password)
      setErrorMessage()
      await user.updateProfile({
        displayName: formData.firstName,
      })
      await user.sendEmailVerification()
      props.onSuccessCreateAccount()
    } catch (error) {
      console.log(error)
      localStorage.removeItem(STORAGE_TEMP_USER_INFO)
      if (error.code === 'auth/email-already-in-use') {
        setErrorMessage($(keys.firebase_signon_errorEmailExists))
      } else {
        setErrorMessage($(keys.firebase_signon_errorCreateAccountFailed))
      }
    }
  }

  const onLanguageRadioSelect = language => {
    keys.setLanguage(language.target.value)
    forceUpdate()
  }

  const handleCancel = () => {
    props.onCancelCreateAccount()
  }

  return (
    <>
      <LeftOutlined onClick={handleCancel} className='floating-login-drawer-back' />
      <Row className='login-drawer-header mb-4' justify='center'>
        <h2>{$(keys.createAccountTitle)}</h2>
      </Row>
      <Row align='center' className='create-account'>
        <Col span={20}>
          {errorMessage && (
            <Row align='center'>
              <Form.Item>
                <span className='error-message-login'>{errorMessage}</span>
              </Form.Item>
            </Row>
          )}
          <Form
            name='formLogin'
            className='form-login'
            layout='vertical'
            hideRequiredMark='true'
            colon='false'
            onFinish={createAccount}
            initialValues={{
              email: props.email,
              preferredLanguage: keys.getLanguage(),
            }}>
            <Form.Item
              name='email'
              validateTrigger='onBlur'
              rules={[
                {
                  type: 'email',
                  message: `- ${$(keys.firebase_signon_errorInvalidEmail)}`,
                },
                {
                  required: true,
                  message: `- ${$(keys.firebase_signon_errorRequiredEmail)}`,
                },
              ]}
              label={$(keys.emailAddress)}>
              <Input
                name='email'
                className='input-email'
                type='email'
                placeholder={$(keys.emailAddressPlaceholder)}
              />
            </Form.Item>
            <Form.Item
              name='firstName'
              rules={[
                {
                  required: true,
                  message: `- ${$(keys.firebase_signon_errorRequiredFirstName)}`,
                },
              ]}
              label={$(keys.myName)}>
              <Input
                name='firstName'
                value='test'
                className='input-first-name'
                placeholder={$(keys.namePlaceholder)}
              />
            </Form.Item>
            <Form.Item
              name='postcode'
              rules={[
                {
                  required: true,
                  message: `- ${$(keys.errorRequiredPostcode)}`,
                },
              ]}
              label={$(keys.postcode)}
              extra={$(keys.postcodeExplanation)}>
              <Input
                name='postcode'
                className='input-postcode'
                placeholder={$(keys.postcodePlaceholder)}
              />
            </Form.Item>
            <Form.Item
              name='password'
              label={$(keys.firebase_signon_password)}
              rules={[
                {
                  required: true,
                  message: `- ${$(keys.firebase_signon_errorRequiredPassword)}`,
                },
                {
                  min: 8,
                  message: `- ${$(keys.firebase_signon_errorMinPassword)}`,
                },
                {
                  validator: (_, value) =>
                    new RegExp('[^A-Za-z0-9]').test(value)
                      ? Promise.resolve()
                      : Promise.reject(`- ${$(keys.firebase_signon_errorSpecCharPassword)}`),
                },
                {
                  validator: (_, value) =>
                    new RegExp('[A-Z]').test(value)
                      ? Promise.resolve()
                      : Promise.reject(`- ${$(keys.firebase_signon_errorUpperCharPassword)}`),
                },
                {
                  validator: (_, value) =>
                    new RegExp('[0-9]').test(value)
                      ? Promise.resolve()
                      : Promise.reject(`- ${$(keys.firebase_signon_errorNumberPassword)}`),
                },
              ]}>
              <Input.Password placeholder={$(keys.passwordPlaceholder)} />
            </Form.Item>
            <Form.Item name='preferredLanguage' className='pb-4' label={$(keys.preferredLanguage)}>
              <Radio.Group onChange={event => onLanguageRadioSelect(event)}>
                <Row>
                  {languagesList.map(language => (
                    <Col sm={12} md={8} key={language}>
                      <Radio value={language}>{$(keys[language])}</Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              className='mb-1'
              name='acceptTermsPrivacy'
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(`- ${$(keys.firebase_signon_errorRequiredTermsPrivacy)}`),
                },
              ]}
              valuePropName='checked'>
              <Checkbox>
                {$(
                  keys.firebase_signon_termsAndPrivacy,
                  <LocalizedLink
                    to={AppRoutes.TermsOfUse}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {$(keys.firebase_signon_termsCta)}
                  </LocalizedLink>,
                  <LocalizedLink
                    to={AppRoutes.PrivacyPolicy}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {$(keys.firebase_signon_privacyCta)}
                  </LocalizedLink>
                )}
              </Checkbox>
            </Form.Item>
            <Form.Item name='subscribeNewsletter' className='pb-3' valuePropName='checked'>
              <Checkbox>{$(keys.firebase_signon_newsletterAccept)}</Checkbox>
            </Form.Item>
            <Form.Item>
              <Row justify='end'>
                <Button
                  type='default'
                  className='input-create-account-cancel'
                  onClick={handleCancel}>
                  {$(keys.firebase_signon_cancel)}
                </Button>
                <Button type='primary' className='input-create-account-save' htmlType='submit'>
                  {$(keys.firebase_signon_createAccount)}
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default CreateAccountComponent
