import create from 'zustand'
import LocalizedStrings from 'react-localization'

import en from './en.json'
import fr from './fr.json'
import de from './de.json'
import it from './it.json'
import pt from './pt.json'

export const keys = new LocalizedStrings({
  en: en,
  fr: fr,
  de: de,
  it: it,
  pt: pt,
})

export const textStore = create((set, get) => ({
  keys: keys,
  history: null,
  //Call this function before using the textStore to provide the necessary variables
  setup: history => {
    set(() => {
      return { history: history }
    })
  },

  setLanguage: lang =>
    set(state => {
      const tempKey = state.keys
      if (!tempKey.getAvailableLanguages().includes(lang)) return

      tempKey.setLanguage(lang)

      return { keys: tempKey }
    }),

  getLanguage: () => get().keys.getLanguage(),

  localizedReplaceHistory: ({ path, params = null, vars = null, state: state = null }) =>
    get().history.replace(localizedUrl(path, params, vars), state),
  localizedPushHistory: ({ path, params = null, vars = null, state: state = null }) =>
    get().history.push(localizedUrl(path, params, vars), state),

  getLocalizedParams: ({ params, paramName }) =>
    params.get(get().keys.getString(paramName, get().keys.getLanguage())),
}))

export const $ = (stringKey, ...rest) => textStore.getState().keys.formatString(stringKey, ...rest)

export const localizedUrl = (path, params, vars) => {
  const localizedParams = createParams({ params })

  if (path === undefined || path === null) {
    return localizedParams
  }

  const language = `/${textStore.getState().getLanguage()}`
  const localizedPath = $(textStore.getState().keys.getString(path), vars)

  return language + localizedPath + localizedParams
}

const createParams = ({ params }) => {
  if (params === undefined || params === null) {
    return ''
  }

  const paramKeys = Object.keys(params)
  let paramString = '?'

  if (paramKeys.length === 0) {
    return ''
  }
  const keys = textStore.getState().keys

  for (let i = paramKeys.length - 1; i >= 0; i--) {
    const key = paramKeys[i]
    paramString += keys.getString(key, keys.getLanguage()) + '=' + params[key]

    if (i > 0) {
      paramString += '&'
    }
  }

  return paramString
}
