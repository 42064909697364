import React, { useState } from 'react'
import useAsyncEffect from 'use-async-effect'
import { app } from '../../../api/firebase'
import { Button, Col, Form, Input, Result, Row } from 'antd'
import { $, localizedUrl, textStore } from '../../../stores/localization/localization'
import LoadingPage from '../../../sharedComponents/LoadingPage'
import { AppRoutes } from '../../../utils/constants/Routing'
import { LocalizedLink } from '../../../utils/localization/LocalizedLink'

const ResetPassword = props => {
  const { actionCode } = props
  const [email] = useState(null)
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')
  const [success, setSuccess] = useState(false)
  const [validCode, setValidCode] = useState(null)
  const [verifiedCode, setVerifiedCode] = useState(false)
  const { keys } = textStore()

  useAsyncEffect(async () => {
    // Verify the password reset code is valid.
    // try {
    //   const email = await app.auth().verifyPasswordResetCode(actionCode)
    //   setEmail(email)
    setValidCode(true)
    setVerifiedCode(true)
    // } catch (error) {
    //   // Invalid or expired action code. Ask user to try to reset the password again.
    //   setError(error.message)
    //   setValidCode(false)
    //   setVerifiedCode(true)
    // }
  }, [])

  const handleResetPassword = async () => {
    // Save the new password.
    try {
      await app.auth().confirmPasswordReset(actionCode, password)
      setSuccess(true)
    } catch (error) {
      setError(error.message)
    }
  }

  if (!verifiedCode) {
    return <LoadingPage />
  } else if (success) {
    return (
      <Result
        status='success'
        title={$(keys.firebase_signon_changePasswordSuccessTitle)}
        subTitle={$(keys.firebase_signon_changePasswordSuccessSubtitle)}
        extra={
          <LocalizedLink to={{ pathName: AppRoutes.Home, state: { openLoginDrawer: true } }}>
            <Button type='primary'>{$(keys.firebase_signon_signIn)}</Button>
          </LocalizedLink>
        }
      />
    )
  } else if (verifiedCode && validCode) {
    return (
      <Row className='mt-3 mb-3' align='center'>
        <Col xs={24} md={16} lg={12}>
          <h2>{$(keys.firebase_signon_changePasswordTitle)}</h2>
          <h3>{email}</h3>

          <Form className='form-reset-password' onFinish={handleResetPassword} layout='vertical'>
            <Form.Item
              name='password'
              label={$(keys.firebase_signon_formNewPassword)}
              rules={[
                {
                  required: true,
                  message: `- ${$(keys.firebase_signon_errorRequiredPassword)}`,
                },
                {
                  min: 8,
                  message: `- ${$(keys.firebase_signon_errorMinPassword)}`,
                },
                {
                  validator: (_, value) =>
                    new RegExp('[^A-Za-z0-9]').test(value)
                      ? Promise.resolve()
                      : Promise.reject(`- ${$(keys.firebase_signon_errorSpecCharPassword)}`),
                },
                {
                  validator: (_, value) =>
                    new RegExp('[A-Z]').test(value)
                      ? Promise.resolve()
                      : Promise.reject(`- ${$(keys.firebase_signon_errorUpperCharPassword)}`),
                },
                {
                  validator: (_, value) =>
                    new RegExp('[0-9]').test(value)
                      ? Promise.resolve()
                      : Promise.reject(`- ${$(keys.firebase_signon_errorNumberPassword)}`),
                },
              ]}>
              <Input
                onChange={e => setPassword(e.target.value)}
                value={password}
                type='password'
                className='rounded'
                placeholder={$(keys.firebase_signon_formNewPassword)}
              />
            </Form.Item>
            <Row justify='end'>
              <Button type='primary' htmlType='submit'>
                {$(keys.firebase_signon_save)}
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    )
  } else if (verifiedCode && !validCode) {
    return (
      <Result
        status='error'
        title={$(keys.firebase_signon_changePasswordErrorTitle)}
        subTitle={error}
        extra={
          <Button type='primary' href={localizedUrl(AppRoutes.Home)}>
            {$(keys.firebase_signon_backToHome)}
          </Button>
        }
      />
    )
  }
}

export default ResetPassword
