import create from 'zustand'
import { LAST_SELECTED_MODE } from '../utils/helpers/localStorageKeys'
import { MODE_LIST } from '../pages/heroes/Heroes'

export const menuStore = create(set => ({
  activePage: '',
  setActivePage: activePage => set({ activePage }),
  loginDrawerVisible: false,
  setLoginDrawerVisible: loginDrawerVisible => set({ loginDrawerVisible }),
  mapMode: MODE_LIST,
  setMapMode: mapMode => {
    localStorage.setItem(LAST_SELECTED_MODE, mapMode)
    set({ mapMode })
  },
}))
