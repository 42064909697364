import React, { useState } from 'react'
import { Button, Col, Form, Input, Result, Row } from 'antd'
import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined'
import { auth } from '../../api/firebase'
import { $, textStore } from '../../stores/localization/localization'
import './loginDrawer.less'
import { AppRoutes } from '../../utils/constants/Routing'
import { LocalizedLink } from '../../utils/localization/LocalizedLink'

const RecoverPasswordComponent = props => {
  const [recoverEmailSent, setRecoverEmailSent] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const { keys } = textStore()

  const sendRecoverEmail = async formData => {
    try {
      await auth.sendPasswordResetEmail(formData.email)
      setRecoverEmailSent(formData.email)
    } catch (error) {
      console.log(error)
      if (error.code === 'auth/user-not-found') {
        setRecoverEmailSent(formData.email)
      } else {
        setErrorMessage($(keys.firebase_signon_errorRecoverPasswordFailed))
      }
    }
  }

  const cancelRecoverPassword = () => {
    props.onCancelRecover()
  }

  const renderFormEmailRecover = () => {
    return (
      <>
        <LeftOutlined onClick={cancelRecoverPassword} className='floating-login-drawer-back' />
        <Row className='login-drawer-header mb-4' justify='center'>
          <h2>{$(keys.firebase_signon_recoverPassword)}</h2>
        </Row>
        <Row align='center' className='recover-password mb-3'>
          <Col span={20}>
            <div className='mb-3'>{$(keys.firebase_signon_instructionsEmailResetPassword)}</div>
            {errorMessage && (
              <Row align='center'>
                <Form.Item>
                  <span className='error-message-login'>{errorMessage}</span>
                </Form.Item>
              </Row>
            )}
            <Form
              name='formLoginEmail'
              className='form-login-email'
              layout='vertical'
              hideRequiredMark='true'
              colon='false'
              onFinish={sendRecoverEmail}
              initialValues={{
                email: props.email,
              }}>
              <Form.Item
                name='email'
                validateTrigger='onBlur'
                rules={[
                  {
                    type: 'email',
                    message: `- ${$(keys.firebase_signon_errorInvalidEmail)}`,
                  },
                  {
                    required: true,
                    message: `- ${$(keys.firebase_signon_errorRequiredEmail)}`,
                  },
                ]}>
                <Input
                  name='email'
                  className='input-email'
                  type='email'
                  placeholder={$(keys.firebase_signon_email)}
                />
              </Form.Item>
              <Form.Item>
                <Row justify='end'>
                  <Button
                    type='default'
                    className='input-recover-cancel'
                    onClick={cancelRecoverPassword}>
                    {$(keys.firebase_signon_cancel)}
                  </Button>
                  <Button type='primary' className='input-recover-send' htmlType='submit'>
                    {$(keys.firebase_signon_send)}
                  </Button>
                </Row>
              </Form.Item>
              <Form.Item>
                <Row justify='end' className='terms-privacy-footer'>
                  <LocalizedLink
                    to={AppRoutes.TermsOfUse}
                    target='_blank'
                    className='terms-small'
                    rel='noopener noreferrer'>
                    {$(keys.firebase_signon_termsCta)}
                  </LocalizedLink>
                  <LocalizedLink
                    to={AppRoutes.PrivacyPolicy}
                    target='_blank'
                    className='privacy-small'
                    rel='noopener noreferrer'>
                    {$(keys.firebase_signon_privacyCta)}
                  </LocalizedLink>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    )
  }

  const renderRecoverEmailSent = () => {
    return (
      <>
        <LeftOutlined onClick={cancelRecoverPassword} className='floating-login-drawer-back' />
        <Row className='login-drawer-header mb-4' justify='center'>
          <h2>{$(keys.firebase_signon_recoverPassword)}</h2>
        </Row>
        <Row align='center' className='recover-password mb-3'>
          <Col span={20}>
            <Result
              status='success'
              title={$(keys.firebase_signon_checkEmail)}
              subTitle={$(keys.firebase_signon_followInstructionsRecover, recoverEmailSent)}
              extra={
                <Button type='primary' onClick={cancelRecoverPassword}>
                  {$(keys.back)}
                </Button>
              }
            />
          </Col>
        </Row>
      </>
    )
  }

  if (recoverEmailSent) {
    return renderRecoverEmailSent()
  } else {
    return renderFormEmailRecover()
  }
}

export default RecoverPasswordComponent
