const THUMB_PREFIX = 'thumbs%2F'
const PLACE_FOLDER_NAME = '/places%2F'
const FILE_TYPE_REGEXP = /[^\s]+(\.(jpg|jpeg|png|gif|bmp|svg|JPG|JPEG|PNG|GIF|BMP|SVG))/

export const checkPictureUrl = url => {
  if (url.substring(url.length - 5, url.length - 1) === '.png' || '.jpg') {
    return url
  } else {
    return url + '.png'
  }
}

export const changePhotosToThumbnails = (photos, width, height) => {
  const newPhotos = []
  const suffix = `_${width}x${height}`
  photos.forEach(photo => {
    let prefixIndex = photo.lastIndexOf(PLACE_FOLDER_NAME)
    let suffixIndex

    if (FILE_TYPE_REGEXP.test(photo)) suffixIndex = photo.lastIndexOf('.')
    else suffixIndex = photo.lastIndexOf('?')

    if (prefixIndex === -1 || suffixIndex === -1) {
      newPhotos.push(photo)
    } else {
      prefixIndex = prefixIndex + 10
      newPhotos.push(
        photo.substring(0, prefixIndex) +
          THUMB_PREFIX +
          photo.substring(prefixIndex, suffixIndex) +
          suffix +
          photo.substring(suffixIndex)
      )
    }
  })

  return newPhotos
}
