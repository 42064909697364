import { Menu } from 'antd'
import React from 'react'
import storeGreen from '../../../assets/store_green.svg'
import storeGrey from '../../../assets/store_grey.svg'
import tagGreen from '../../../assets/tag_green.svg'
import tagGrey from '../../../assets/tag_grey.svg'
import sparklesGreen from '../../../assets/sparkles_green.svg'
import sparklesGrey from '../../../assets/sparkles_grey.svg'
import { $, textStore } from '../../../stores/localization/localization'
import NavigationMenuItem from './NavigationMenuItem'
import { trackGTMEvent } from '../../../utils/helpers/gtmTracking'
import { safelyRetrieveStorage, USER_COUNTRY } from '../../../utils/helpers/localStorageKeys'
import { useHistory } from 'react-router-dom'
import { menuStore } from '../../../stores/menuStore'
import { getZipFromStorage } from '../../../utils/zipParameter'
import { Parameters as HeroesParams } from '../../../pages/heroes/Heroes'
import { AppRoutes } from '../../../utils/constants/Routing'

const NavDiscovery = () => {
  const { keys, localizedPushHistory } = textStore()
  const history = useHistory()

  const { activePage } = menuStore()

  const searchParams = new URLSearchParams(history.location.search)
  const getZip = getZipFromStorage(searchParams)

  return (
    <Menu
      mode='horizontal'
      selectedKeys={activePage}
      className='nav__menu nav__menu-discovery'
      disabledOverflow>
      <Menu.Item
        key={AppRoutes.Heroes}
        onClick={() => {
          trackGTMEvent('General', 'General-heroes', 'General-heroes-top-nav')
          localizedPushHistory({
            path: AppRoutes.Heroes,
            params: {
              [HeroesParams.Country]: safelyRetrieveStorage(USER_COUNTRY),
              [HeroesParams.Zip]: getZip,
            },
          })
        }}>
        <NavigationMenuItem
          imageActive={storeGreen}
          imageInactive={storeGrey}
          text={$(keys.heroes)}
          page={AppRoutes.Heroes}
        />
      </Menu.Item>
      <Menu.Item
        key={AppRoutes.Products}
        onClick={() => {
          trackGTMEvent('General', 'General-products', 'General-products-top-nav')
          localizedPushHistory({
            path: AppRoutes.Products,
          })
        }}>
        <NavigationMenuItem
          imageActive={tagGreen}
          imageInactive={tagGrey}
          text={$(keys.products)}
          page={AppRoutes.Products}
        />
      </Menu.Item>
      <Menu.Item
        key={AppRoutes.Activities}
        onClick={() => {
          trackGTMEvent('General', 'General-activities', 'General-activities-top-nav')
          localizedPushHistory({
            path: AppRoutes.Activities,
          })
        }}>
        <NavigationMenuItem
          imageActive={sparklesGreen}
          imageInactive={sparklesGrey}
          text={$(keys.activities)}
          page={AppRoutes.Activities}
        />
      </Menu.Item>
    </Menu>
  )
}

export default NavDiscovery
