import { Button } from 'antd'
import closeIcon from '../../assets/close-gray.svg'
import { $, textStore } from '../../stores/localization/localization'
import './closeButton.less'
import ButtonIcon from '../ButtonIcon/ButtonIcon'

const CloseButton = () => {
  const { keys } = textStore()

  return (
    <Button type='link' className='close-button' icon={<ButtonIcon imgSrc={closeIcon} />}>
      {$(keys.close)}
    </Button>
  )
}

export default CloseButton
