import React from 'react'
import { Route } from 'react-router-dom'
import NotAuthorized from '../../components/NotAuthorized'
import { usePlace } from '../hooks/usePlace'
import LoadingPage from '../../sharedComponents/LoadingPage'
import { textStore } from '../../stores/localization/localization'
import { AppRoutes } from '../constants/Routing'

const RedirectLogin = ({ rest }) => {
  const { localizedReplaceHistory } = textStore()

  localizedReplaceHistory({
    path: AppRoutes.Home,
    state: { referrer: rest.path, openLoginDrawer: true },
  })
  return <></>
}

export const PrivateRoute = ({
  component: Component,
  authenticated,
  isAuthorized = false,
  verifiedEmail = true,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      authenticated === true ? (
        isAuthorized ? (
          verifiedEmail ? (
            <Component {...props} />
          ) : (
            <RedirectLogin rest={rest} />
          )
        ) : (
          <NotAuthorized />
        )
      ) : (
        <RedirectLogin rest={rest} />
      )
    }
  />
)

export const PrivatePlaceRoute = ({
  component: Component,
  authenticated,
  userId,
  isAuthorized,
  verifiedEmail = true,
  ...rest
}) => {
  const { city, name } = rest.computedMatch.params
  const placeData = usePlace(city, name)
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true ? (
          placeData.placeLoading ? (
            <LoadingPage />
          ) : placeData.place?.ownerId === userId || isAuthorized ? (
            verifiedEmail ? (
              <Component {...props} />
            ) : (
              <RedirectLogin rest={rest} />
            )
          ) : (
            <NotAuthorized />
          )
        ) : (
          <RedirectLogin rest={rest} />
        )
      }
    />
  )
}
