import React, { useState } from 'react'
import { Button, Card, Col, Row } from 'antd'
import { db } from '../../../api/firebase'
import { textStore } from '../../../stores/localization/localization'
import { useFirestoreDoc } from '../../../utils/hooks/useFirestore'
import { getExternalUrl } from '../../../utils/helpers/getExternalUrl'
import './customCard.less'
import { trackGTMEvent } from '../../../utils/helpers/gtmTracking'

const CustomCard = () => {
  const [customCard, setCustomCard] = useState()
  const { keys } = textStore()
  const ref = db.collection('manager').doc('customCard')
  const { isLoading } = useFirestoreDoc(ref, setCustomCard)

  const text = customCard ? customCard.text[keys.getLanguage()] : ''
  const cta = customCard ? customCard.cta[keys.getLanguage()] : ''
  const textClass = text?.length > 100 ? 'custom-card-small-text' : ''

  if (!isLoading && customCard.visible)
    return (
      <Col xl={6} lg={8} md={12} sm={24} className='discover-list-item custom-card-container'>
        <Card
          className='custom-card'
          style={{
            backgroundImage: `url(${customCard.backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}>
          <Row className='custom-card-container' align='middle' justify='center'>
            <Col>
              <Row>
                <Col>
                  <h2 align='center' className={`custom-card-title ${textClass}`}>
                    {text}
                  </h2>
                </Col>
              </Row>
              <Row align='center'>
                <Col>
                  <img className='custom-card-img' alt='Add hero' src={customCard.logo} />
                </Col>
              </Row>
              <Row align='center' className='mt-2'>
                <Col>
                  <a href={getExternalUrl(customCard.url)} target='_blank' rel='noreferrer'>
                    <Button
                      size='large'
                      type='primary'
                      onClick={() =>
                        trackGTMEvent(
                          'Visitor',
                          'Visitor-click',
                          'Visitor-click-heroes-list-promotion-card'
                        )
                      }>
                      {cta}
                    </Button>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    )
  else return <></>
}

export default CustomCard
