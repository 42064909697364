import React, { useState } from 'react'
import useAsyncEffect from 'use-async-effect'
import { app, db } from '../../../api/firebase'
import LoadingPage from '../../../sharedComponents/LoadingPage'
import { Button, Result } from 'antd'
import { $, textStore } from '../../../stores/localization/localization'
import firebase from 'firebase'
import { AppRoutes } from '../../../utils/constants/Routing'
import { LocalizedButton } from '../../../utils/localization/LocalizedButton'

const VerifyEmail = ({ actionCode }) => {
  const [error, setError] = useState('')
  const [validCode, setValidCode] = useState(null)
  const [verifiedCode, setVerifiedCode] = useState(false)
  const [didSendEmail, setDidSendEmail] = useState(false)
  const { keys } = textStore()
  const user = firebase.auth().currentUser

  const sendEmailVerification = async () => {
    if (user && !user.emailVerified) {
      try {
        await user.sendEmailVerification()
        setDidSendEmail(true)
      } catch (error) {
        console.error(error)
      }
    }
  }

  useAsyncEffect(async () => {
    // Verify the password reset code is valid.
    try {
      // Try to apply the email verification code.
      await app.auth().applyActionCode(actionCode)
      await app.auth().currentUser?.reload()
      setValidCode(true)
      setVerifiedCode(true)
      if (user) {
        const userRef = db.doc(`users/${user.uid}`)
        await userRef.set(
          {
            emailVerified: true,
          },
          {
            merge: true,
          }
        )
      }
    } catch (error) {
      // Code is invalid or expired. Ask the user to verify their email address again.
      setError(error.message)
      setValidCode(false)
      setVerifiedCode(true)
    }
  }, [])

  if (!verifiedCode) {
    return <LoadingPage />
  } else if ((verifiedCode && validCode) || user.emailVerified) {
    return (
      <Result
        status='success'
        title={$(keys.firebase_signon_verifyYourEmailSuccessTitle)}
        subTitle={$(keys.firebase_signon_verifyYourEmailSuccessSubtitle)}
        extra={
          <LocalizedButton type='primary' to={AppRoutes.Home}>
            {$(keys.firebase_signon_backToHome)}
          </LocalizedButton>
        }
      />
    )
  } else if (verifiedCode && !validCode) {
    return (
      <Result
        status='error'
        title={$(keys.firebase_signon_verifyYourEmailErrorTitle)}
        subTitle={error}
        extra={[
          didSendEmail ? (
            <p>$(keys.firebase_signon_confirmEmail)</p>
          ) : (
            user &&
            !user.emailVerified && (
              <Button type='primary' key='send' onClick={sendEmailVerification}>
                {$(keys.firebase_signon_verifyYourEmailSendAgain)}
              </Button>
            )
          ),
          <LocalizedButton type='primary' key='home' to={AppRoutes.Home}>
            {$(keys.firebase_signon_backToHome)}
          </LocalizedButton>,
        ]}
      />
    )
  }
}

export default VerifyEmail
