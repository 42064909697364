import { DownOutlined, GlobalOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Menu, Row } from 'antd'
import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { fetchSetUserLanguage } from '../../api/sessionFetchers'
import { $, textStore } from '../../stores/localization/localization'
import { UserContext } from '../providers/UserProvider'
import { getMatchingRouteInLanguage } from './components/routeTranslation'

///OBS: It is important for complicated setups that the order which switches are inserted matches the order of the route names in the translation keys.
///If this is not respected, urls which create a conflict in the language switcher will get confused and risk selecting the wrong translated URL.
///An example of this could be "/place/to/{go}" and "/place/{to}/{go}" if the switch and the language keys don't agree on the order, the wrong URL will be selected on switch.
export const LanguageSwitcher = () => {
  const { keys } = textStore()
  const user = useContext(UserContext)
  const { pathname } = useLocation()
  const history = useHistory()

  const changeLanguage = newLang => {
    history.replace(getMatchingRouteInLanguage(pathname, newLang, { history: history }))

    if (user) {
      fetchSetUserLanguage(user.uid, newLang).then()
    }
  }

  const languageMenu = (
    <Menu>
      {keys.getAvailableLanguages().map((lang, index) => (
        <Menu.Item key={index} onClick={() => changeLanguage(lang)}>
          {$(keys[lang])}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={languageMenu} placement='bottomCenter'>
      <Button size='large' className='footer-language-button'>
        <Row justify='space-between'>
          <Col>
            <GlobalOutlined /> {$(keys[keys.getLanguage()])}
          </Col>
          <Col>
            <DownOutlined />
          </Col>
        </Row>
      </Button>
    </Dropdown>
  )
}
