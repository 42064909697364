export const AppRoutes = {
  Home: 'routesHome',
  NoMatch: 'routes404',
  HowDoesItWork: 'routesHowDoesItWork',
  Search: 'routesSearch',
  Heroes: 'routesHeroes',
  Products: 'routesProducts',
  Activities: 'routesActivities',
  SwitchActivity: 'routesActivitySwitchRoute',
  Activity: 'routesActivity',
  AddHero: 'routesAddHero',
  SwitchHeroById: 'routesHeroByIdSwitchRoute',
  HeroById: 'routesHeroById',
  SwitchEditHeroById: 'routesEditHeroByIdSwitchRoute',
  EditHeroById: 'routesEditHeroById',
  SwitchHero: 'routesHeroSwitchRoute',
  Hero: 'routesHero',
  SwitchContactProduct: 'routesContactProductSwitchRoute',
  ContactProduct: 'routesContactProduct',
  RecoverPassword: 'routesRecoverPassword',
  Welcome: 'routesWelcome',
  Subscriptions: 'routesSubscriptions',
  Businesses: 'routesBusiness',
  ManageClaims: 'routesManageClaims',
  Manager: 'routesManager',
  SwitchEditHero: 'routesEditHeroSwitchRoute',
  EditHero: 'routesEditHero',
  SwitchHeroCommunications: 'routesHeroCommunicateSwitchRoute',
  HeroCommunications: 'routesHeroCommunicate',
  SwitchHeroActivities: 'routesHeroActivitiesSwitchRoute',
  HeroActivities: 'routesHeroActivities',
  SwitchHeroProducts: 'routesHeroProductsSwitchRoute',
  HeroProducts: 'routesHeroProducts',
  TermsOfUse: 'routesTermsOfUse',
  PrivacyPolicy: 'routesPrivacyPolicy',
}

export const CoteriesWebsite = 'https://coteries.com'
export const CoteriesFacebook = 'https://facebook.com/localheroesswitzerland'
export const CoteriesInstagram = 'https://www.instagram.com/local_heroes_ch/'
export const LocalHeroesBlog = 'https://blog.local-heroes.ch/'
export const LocalHeroesMailTo = 'mailto:hero@local-heroes.ch'
