import React, { useState } from 'react'
import { Button, Checkbox, Col, Dropdown, Grid, Menu, Row } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import SelectZipModal from './SelectZipModal'
import { MODE_MAP } from '../../pages/heroes/Heroes'
import { $, textStore } from '../../stores/localization/localization'
import { PlaceType } from '../../utils/constants/PlaceType'
import { PlaceProduct, PlaceProductAlimentary } from '../../utils/constants/PlaceProduct'
import chevronDown from '../../assets/chevron-down.svg'
import mapMarketAlt from '../../assets/map-marker-alt.svg'
import mapModeGreen from '../../assets/map_mode_green.svg'
import './discoverFilterBar.less'
import { trackGTMEvent } from '../../utils/helpers/gtmTracking'
import { menuStore } from '../../stores/menuStore'

export const discoverPages = {
  HEROES: 'heroes',
  PRODUCTS: 'products',
  ACTIVITIES: 'activities',
  NONE: 'none',
}

const { useBreakpoint } = Grid

const DiscoverFilterBar = ({
  activePage,
  city,
  postalCode,
  filterChanged,
  filters,
  onModeChange,
  productList,
}) => {
  const [showSelectZipModal, setShowSelectZipModal] = useState(false)
  const [selectedHeroesCategory, setSelectedHeroesCategory] = useState()
  const [selectedProductsCategory, setSelectedProductsCategory] = useState()
  const { keys } = textStore()
  const screens = useBreakpoint()
  const { setMapMode } = menuStore()

  const filterCategory = (type, value, setCategory) => {
    filterChanged(type, value)
    setCategory($(keys[value]))
  }

  const heroesCategoryMenu = (
    <Menu>
      {Object.keys(PlaceType).map(type => (
        <Menu.Item
          key={type}
          value={PlaceType[type]}
          onClick={values =>
            filterCategory('category', values.item.props.value, setSelectedHeroesCategory)
          }>
          {$(keys[PlaceType[type]])}
        </Menu.Item>
      ))}
    </Menu>
  )

  const productsCategoryMenu = () => {
    if (!productList) return

    return (
      <Menu>
        <Menu.ItemGroup title={$(keys.alimentary)}>
          {Object.keys(PlaceProductAlimentary)
            .filter(
              type =>
                productList.filter(product => product.categoryId === PlaceProductAlimentary[type])
                  .length > 0
            )
            .map(type => {
              return {
                key: PlaceProductAlimentary[type],
                value: `${$(keys[PlaceProductAlimentary[type]])}`,
              }
            })
            .sort((a, b) => a.value.localeCompare(b.value))
            .map(prod => (
              <Menu.Item
                key={prod.key}
                value={prod.key}
                onClick={values =>
                  filterCategory('categoryId', values.item.props.value, setSelectedProductsCategory)
                }>
                {prod.value}
              </Menu.Item>
            ))}
        </Menu.ItemGroup>
        <Menu.ItemGroup title={$(keys.nonAlimentary)}>
          {Object.keys(PlaceProduct)
            .filter(
              type =>
                productList?.filter(product => product.categoryId === PlaceProduct[type]).length > 0
            )
            .map(type => {
              return {
                key: PlaceProduct[type],
                value: `${$(keys[PlaceProduct[type]])}`,
              }
            })
            .sort((a, b) => a.value.localeCompare(b.value))
            .map(prod => (
              <Menu.Item
                key={prod.key}
                value={prod.key}
                onClick={values =>
                  filterCategory('categoryId', values.item.props.value, setSelectedProductsCategory)
                }>
                {prod.value}
              </Menu.Item>
            ))}
        </Menu.ItemGroup>
      </Menu>
    )
  }

  const checkBoxFilterChanged = event => {
    const filterName = event.target.name
    filterChanged(filterName, event.target.checked)
  }

  const filterMenu = (
    <Menu>
      <Menu.Item key='doesDeliver'>
        <Checkbox
          name='doesDeliver'
          checked={filters?.doesDeliver}
          onChange={checkBoxFilterChanged}>
          {$(keys.homeDelivery)}
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='isBio'>
        <Checkbox name='isBio' checked={filters?.isBio} onChange={checkBoxFilterChanged}>
          {$(keys.inputIsBio)}
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='acceptsGiftCard'>
        <Checkbox
          name='acceptsGiftCard'
          checked={filters?.acceptsGiftCard}
          onChange={checkBoxFilterChanged}>
          {$(keys.giftCard)}
        </Checkbox>
      </Menu.Item>
    </Menu>
  )

  const clickMapMode = () => {
    trackGTMEvent('General', 'General-map-view')
    setMapMode(MODE_MAP)
  }

  return (
    <>
      <Row className='mt-4 discover-filter-container' justify='center' gutter={[8, 8]}>
        <Col span={24}>
          {activePage === discoverPages.HEROES && (
            <Row
              className={`scrollable-filters ${!screens.md ? 'scrollable-filters-scroll' : ''}`}
              justify={screens.md ? 'center' : 'start'}>
              <div className='filters-collection'>
                <Dropdown overlay={heroesCategoryMenu} trigger={['click']} placement='bottomCenter'>
                  <Button className='filter-button hero-categories-dropdown'>
                    {selectedHeroesCategory ? (
                      <>
                        <span>{selectedHeroesCategory}</span>
                        <a
                          className='ml-2 green'
                          onClick={() =>
                            filterCategory('category', null, setSelectedHeroesCategory)
                          }>
                          <CloseOutlined />
                        </a>
                      </>
                    ) : (
                      <>
                        {$(keys.whatLookingFor)}
                        <img className='icons-right' src={chevronDown} alt='Filters' />
                      </>
                    )}
                  </Button>
                </Dropdown>
                <Button className='filter-button' onClick={() => setShowSelectZipModal(true)}>
                  <img className='icons' src={mapMarketAlt} alt='Filters' />
                  {postalCode} {city}
                </Button>
                <Dropdown overlay={filterMenu} trigger={['click']}>
                  <Button className='filter-button'>
                    {$(keys.filters)}
                    <img className='icons-right' src={chevronDown} alt='Filters' />
                  </Button>
                </Dropdown>
                <Button className='filter-button' onClick={clickMapMode}>
                  <img className='icons' src={mapModeGreen} alt='Filters' />
                  {$(keys.discoverMapMode)}
                </Button>
              </div>
            </Row>
          )}
          {activePage === discoverPages.PRODUCTS && (
            <Row justify='center'>
              <Dropdown
                overlay={productsCategoryMenu}
                trigger={['click']}
                className='dropdown-select-product-category'
                placement='bottomCenter'>
                <Button className='filter-button product-categories-dropdown'>
                  {selectedProductsCategory ? (
                    <>
                      <span className='dropdown-products-text'>{selectedProductsCategory}</span>
                      <a
                        className='mx-2 icons-right dropdown-products-icon-delete'
                        onClick={() =>
                          filterCategory('categoryId', null, setSelectedProductsCategory)
                        }>
                        <CloseOutlined />
                      </a>
                    </>
                  ) : (
                    <>
                      <span className='dropdown-products-text'>{$(keys.whatLookingFor)}</span>
                      <img
                        className='mx-2 icons-right dropdown-products-icon'
                        src={chevronDown}
                        alt='Filters'
                      />
                    </>
                  )}
                </Button>
              </Dropdown>
            </Row>
          )}
        </Col>
      </Row>
      <SelectZipModal showModal={showSelectZipModal} setShowModal={setShowSelectZipModal} />
    </>
  )
}

export default DiscoverFilterBar
