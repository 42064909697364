import React, { useState } from 'react'
import { Card, Col, Layout, Row, Skeleton } from 'antd'
import _ from 'lodash'
import HeroCard from '../../../sharedComponents/entityCards/hero/HeroCard'
import CustomCard from '../../../sharedComponents/entityCards/hero/CustomCard'
import AddHeroCard from '../../../sharedComponents/entityCards/hero/AddHeroCard'
import NewsLetterCard from '../../../sharedComponents/entityCards/hero/NewsletterCard'
import DiscoverFilterBar, {
  discoverPages,
} from '../../../sharedComponents/searchAndFilter/DiscoverFilterBar'
import '../heroes.less'

const ADD_HERO_CARD = 'ADD_HERO_CARD'
const NEWSLETTER_CARD = 'NEWSLETTER_CARD'
const CUSTOM_CARD = 'CUSTOM_CARD'
const CUSTOM_CARD_INDEX = Math.floor(Math.random() * 11 + 10)
const ITEMS_TO_LOAD = 20

const HeroList = ({ isLoading, places, placeDistances, city, postalCode, subscriptions, user }) => {
  const [filters, setFilters] = useState({})
  const [visibleItems, setVisibleItems] = useState(ITEMS_TO_LOAD)
  const [showNewsletterCard] = useState(false) //useState(checkIfShowNewsletter())

  let loadingItems = false

  const getProcessedPlaceList = () => {
    //Add the distance to all items
    let sortedPlaces = places

    //Filter all lists
    Object.keys(filters).forEach(filter => {
      let value = filters[filter]

      if (value) {
        switch (filter) {
          case 'promotion':
            sortedPlaces = sortedPlaces.filter(
              place => !!place.promotionWelqome || !!place.promotionAideAuxEnt
            )
            break
          default:
            sortedPlaces = sortedPlaces.filter(place => place[filter] === value)
            break
        }
      }
    })

    //Add the distance to all items
    sortedPlaces = sortedPlaces.map(place => {
      place.distanceFromMe = placeDistances[place.id]
      return place
    })

    //Sort by distance
    sortedPlaces = _.orderBy(sortedPlaces, 'distanceFromMe')

    loadingItems = false
    // Slice only to the visible item limit
    sortedPlaces = sortedPlaces.slice(0, visibleItems)

    /// Add extra cards

    // Add 'Add hero' registration card as the 1st item
    sortedPlaces.splice(0, 0, { cardType: ADD_HERO_CARD })

    // Add newsletter registration card as the 5th item
    if (showNewsletterCard) {
      sortedPlaces.splice(4, 0, { cardType: NEWSLETTER_CARD })
    }

    // Add custom card at a random index between 10 and 20
    sortedPlaces.splice(CUSTOM_CARD_INDEX, 0, { cardType: CUSTOM_CARD })

    return sortedPlaces
  }

  //Load more items when making it to the bottom of the page.
  window.onscroll = () => {
    if (
      window.scrollY >
        document.documentElement.scrollHeight - document.documentElement.offsetHeight - 400 &&
      !loadingItems
    ) {
      loadingItems = true
      setVisibleItems(visibleItems + ITEMS_TO_LOAD)
    }
  }

  const filterChanged = (filterName, values) => {
    setVisibleItems(ITEMS_TO_LOAD)
    setFilters(filters => ({ ...filters, [filterName]: values }))
  }

  return (
    <Layout.Content>
      <Row justify='center'>
        <Col xl={18} xs={23}>
          <DiscoverFilterBar
            activePage={discoverPages.HEROES}
            city={city}
            postalCode={postalCode}
            filterChanged={filterChanged}
            filters={filters}
          />
          {isLoading ? (
            <Row gutter={[16, 16]} className='list-items my-4'>
              {<AddHeroCard key={0} />}
              {_.times(9).map(index => (
                <Col key={index + 1} xl={6} lg={8} md={12} sm={24} className='discover-list-item'>
                  <Card style={{ height: 331 }} bordered='false' className='place-card'>
                    <Skeleton loading={isLoading} avatar active />
                  </Card>
                </Col>
              ))}
            </Row>
          ) : _.isEmpty(places) ? (
            <div>No places found</div>
          ) : (
            <Row gutter={[16, 16]} className='my-4' justify='center'>
              {getProcessedPlaceList().map((place, index) => {
                switch (place.cardType) {
                  case NEWSLETTER_CARD:
                    return <NewsLetterCard key={index} />
                  case ADD_HERO_CARD:
                    return <AddHeroCard key={index} />
                  case CUSTOM_CARD:
                    return <CustomCard key={index} />
                  default:
                    return (
                      <HeroCard
                        key={index}
                        place={place}
                        user={user}
                        isSubscribed={!!subscriptions.find(sub => sub.placeId === place.id)}
                      />
                    )
                }
              })}
            </Row>
          )}
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default HeroList
