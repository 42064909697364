import React from 'react'
import { useLocation } from 'react-router-dom'
import { Layout, Result, Row } from 'antd'
import { $, textStore } from '../../../stores/localization/localization'
import RecoverEmail from './RecoverEmail'
import ResetPassword from './ResetPassword'
import VerifyEmail from './VerifyEmail'
import visual_hero from '../../../assets/visual_hero.svg'
import MetaTags from 'react-meta-tags'
import { AppRoutes } from '../../../utils/constants/Routing'
import { LocalizedButton } from '../../../utils/localization/LocalizedButton'

const Actions = () => {
  const { keys } = textStore()

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const mode = params.get('mode')

  // Get the one-time code from the query parameter.
  const actionCode = params.get('oobCode')

  const component = () => {
    // Handle the user management action.
    switch (mode) {
      case 'recoverEmail':
        // Display reset password handler and UI.
        return <RecoverEmail actionCode={actionCode} />
      case 'resetPassword':
        // Display email recovery handler and UI.
        return <ResetPassword actionCode={actionCode} />
      case 'verifyEmail':
        // Display email verification handler and UI.
        return <VerifyEmail actionCode={actionCode} />
      default:
        // Error: invalid mode.
        return (
          <Result
            status='error'
            extra={
              <LocalizedButton type='primary' to={AppRoutes.Home}>
                {$(keys.firebase_signon_backToHome)}
              </LocalizedButton>
            }
          />
        )
    }
  }

  return (
    <Layout>
      <MetaTags>
        <title>Local Heroes</title>
        <meta name='title' content={$(keys.metaAddPlaceTitle)} />
        <meta name='description' content={$(keys.metaAddPlaceDescription)} />
        <meta name='robots' content='Index,follow' />
        <meta property='og:locale' content={keys._interfaceLanguage} />
        <meta property='og:type' content='article' />
        <meta property='og:title' content={$(keys.metaAddPlaceTitle)} />
        <meta property='og:description' content={$(keys.metaAddPlaceDescription)} />
        <meta property='og:url' content='https://local-heroes.ch/userManagement' />
        <meta property='og:site_name' content='Local Heroes' />
        <meta property='og:image' content={window.location.origin + visual_hero} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={$(keys.metaAddPlaceTitle)} />
        <meta name='twitter:description' content={$(keys.metaAddPlaceDescription)} />
        <meta name='twitter:image' content={window.location.origin + visual_hero} />
      </MetaTags>
      <Layout.Content>
        <Row align='center' className='login-body'>
          <div className='container'>{component()}</div>
        </Row>
      </Layout.Content>
    </Layout>
  )
}

export default Actions
