import React, { useState } from 'react'
import useAsyncEffect from 'use-async-effect'
import { app } from '../../../api/firebase'
import { handleError } from '../../../utils/helpers/handleError'
import LoadingPage from '../../../sharedComponents/LoadingPage'
import { $, textStore } from '../../../stores/localization/localization'
import { Button, Col, Result, Row } from 'antd'
import { LocalizedButton } from '../../../utils/localization/LocalizedButton'
import { AppRoutes } from '../../../utils/constants/Routing'

const RecoverEmail = ({ actionCode }) => {
  const [error, setError] = useState('')
  const [restoredEmail, setRestoredEmail] = useState('')
  const [resetSent, setResetSent] = useState(false)
  const [validCode, setValidCode] = useState(null)
  const [verifiedCode, setVerifiedCode] = useState(false)
  const { keys } = textStore()

  useAsyncEffect(async () => {
    // Verify the password reset code is valid.
    try {
      const info = await app.auth().checkActionCode(actionCode)
      // Get the restored email address.
      const restoredEmail = info.data.email
      // Revert to the old email.
      await app.auth().applyActionCode(actionCode)
      setRestoredEmail(restoredEmail)
      setValidCode(true)
      setVerifiedCode(true)
    } catch (error) {
      // Invalid or expired action code. Ask user to try to reset the password again.
      setError(error.message)
      setValidCode(false)
      setVerifiedCode(true)
      handleError(error)
    }
  }, [])

  const sendReset = async () => {
    try {
      // You might also want to give the user the option to reset their password in case the account was compromised:
      await app.auth().sendPasswordResetEmail(restoredEmail)
      setResetSent(true)
    } catch (error) {
      handleError(error)
    }
  }

  if (!verifiedCode) {
    return <LoadingPage />
  } else if (resetSent) {
    return (
      <Result
        status='success'
        title={$(keys.firebase_signon_recoverEmailSuccessTitle)}
        subTitle={$(keys.firebase_signon_recoverEmailSuccessSubtitle, restoredEmail)}
        extra={
          <LocalizedButton type='primary' to={AppRoutes.Home}>
            {$(keys.firebase_signon_backToHome)}
          </LocalizedButton>
        }
      />
    )
  } else if (verifiedCode && validCode) {
    return (
      <Row className='mt-3 mb-3' align='center'>
        <Col xs={24} md={16} lg={12}>
          <h2>{$(keys.firebase_signon_recoverEmailTitle)}</h2>
          <p>{$(keys.firebase_signon_recoverEmailSubtitle, restoredEmail)}</p>
          <p>
            {$(
              keys.firebase_signon_recoverEmailDescription,
              <Button type='link' onClick={sendReset}>
                {$(keys.firebase_signon_recoverEmailDescriptionCta)}
              </Button>
            )}
          </p>
        </Col>
      </Row>
    )
  } else if (verifiedCode && !validCode) {
    return (
      <Result
        status='error'
        title={$(keys.firebase_signon_recoverEmailErrorTitle)}
        subTitle={$(keys.firebase_signon_recoverEmailErrorSubtitle)}
        extra={
          <LocalizedButton type='primary' key='home' to={AppRoutes.Home}>
            {$(keys.firebase_signon_backToHome)}
          </LocalizedButton>
        }>
        <p>{error}</p>
      </Result>
    )
  }
}

export default RecoverEmail
