import React from 'react'
import { Card, Col, Grid, Row } from 'antd'
import ButtonAddHero from '../../../components/ButtonAddHero/ButtonAddHero'
import { $, textStore } from '../../../stores/localization/localization'
import VisualHero from '../../../assets/visual_hero.svg'
import './addHeroCard.less'
import LogoVaudoise from '../../LogoVaudoise/LogoVaudoise'

const { useBreakpoint } = Grid

const AddHeroCard = () => {
  const { keys } = textStore()
  const screens = useBreakpoint()

  return (
    <Col xl={6} lg={8} md={12} sm={24} className='discover-list-item'>
      <Card className='add-hero-card'>
        <Row className='add-hero-card-container' align='middle' justify='center'>
          <Col span={22}>
            <Row>
              <h2 align='center' className='add-hero-card-title'>
                {$(keys.addHeroCardTitle)}
              </h2>
            </Row>
            <Row align='center'>
              <ButtonAddHero />
            </Row>
            <Row align='center'>
              <img className='card-visual-hero mt-3' alt='Add hero' src={VisualHero} />
            </Row>
            {!screens.xs && <LogoVaudoise text={$(keys.collaborationWith)} />}
          </Col>
        </Row>
      </Card>
    </Col>
  )
}

export default AddHeroCard
