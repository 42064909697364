export const PlaceProduct = {
  COSMETICS: 'cosmetics',
  HOUSEHOLD: 'household',
  HYGIENE: 'hygiene',
  HOME_DECOR: 'homeDecor',
  CLOTHES: 'clothes',
  SHOES: 'shoes',
  JEWELLERY: 'jewellery',
  GAMES_TOYS: 'gamesToys',
  LEATHER_GOODS: 'leatherGoods',
  PERFUMES_SOAPS: 'perfumesSoaps',
  CREAMS_OINTMENTS: 'creamsOintments',
  ESSENTIAL_OILS: 'essentialOils',
  WEAVING: 'weaving',
  POTTERY: 'pottery',
  ACCESSORIES: 'accessories',
  CHILDRENS_FASHION: 'childrensFashion',
}

export const PlaceProductAlimentary = {
  FRUITS_VEGETABLES: 'fruitsVegetables',
  DAIRY_PRODUCTS: 'dairyProducts',
  MEAT: 'meat',
  FISH: 'fish',
  SOFT_DRINKS: 'softDrinks',
  ALCOHOL_DRINKS: 'alcoholDrinks',
  COFFEE: 'coffee',
  OILS: 'oils',
  CEREALS: 'cereals',
  BREAD_PASTRIES: 'breadPastries',
  DESSERTS: 'desserts',
  MEALS: 'meals',
  WITHOUT_GLUTEN: 'withoutGluten',
  DRIED_FRUITS: 'driedFruits',
  SWEETS: 'sweets',
  HONEY_JAMS_SYRUPS: 'honeyJamsSyrups',
  CANNED_FOOD: 'cannedFood',
  SPICES: 'spices',
  EGGS: 'eggs',
}
