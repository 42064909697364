import { useState } from 'react'
import { db } from '../../api/firebase'
import { useFirestoreDoc, useFirestoreQuery } from './useFirestore'

export const usePlace = (city, name, user) => {
  const [place, setPlace] = useState(null)
  const query =
    city && name
      ? db.collection('places').where('pathCity', '==', city).where('pathName', '==', name)
      : null

  // Function to get the newsfeed of a place, will be used below (inside setDataFunc)
  const getPlaceNewsFeed = async placeId => {
    const newsFeedRef = db
      .collection(`places/${placeId}/newsFeed`)
      .orderBy('createdTimestamp', 'desc')
      .limit(3)
    const newsFeedData = await newsFeedRef.get()
    const newsFeed = []
    newsFeedData.forEach(data => {
      newsFeed.push({ id: data.id, ...data.data() })
    })
    return newsFeed
  }

  // Function to get the products of a place, will be used below (inside setDataFunc)
  const getPlaceProducts = async placeId => {
    const productsRef = db.collection(`products`).where('placeId', '==', placeId)
    const productsData = await productsRef.get()
    const products = []
    productsData.forEach(data => {
      products.push({ id: data.id, ...data.data() })
    })
    return products
  }

  let setDataFunc
  // If we have a user Id, the response should also include isSubscribed, isClaimed, isClaimApproved
  if (user) {
    setDataFunc = async docs => {
      const auxPlace = docs[0]
      if (auxPlace) {
        // Check if user is subscribed
        const subscriptionRef = db
          .collection(`users/${user.uid}/subscriptions`)
          .where('placeId', '==', auxPlace.id)
        const querySubscription = await subscriptionRef.get()
        let subscriptionCount = 0
        querySubscription.forEach(() => {
          subscriptionCount++
        })
        auxPlace.isSubscribed = subscriptionCount > 0
        // Check if user has a claim
        const claimRef = db
          .collection(`users/${user.uid}/claims`)
          .where('placeId', '==', auxPlace.id)
        const queryClaims = await claimRef.get()
        const claims = []
        queryClaims.forEach(data => {
          claims.push({ id: data.id, ...data.data() })
        })
        auxPlace.isClaimed = claims.length > 0
        auxPlace.isClaimApproved = claims.length > 0 && claims[0].isClaimApproved
        auxPlace.newsFeed = await getPlaceNewsFeed(auxPlace.id)
        auxPlace.productsData = await getPlaceProducts(auxPlace.id)
      }
      // Set place in state
      setPlace(auxPlace)
      return auxPlace
    }
  } else {
    setDataFunc = async docs => {
      const auxPlace = docs[0]
      if (auxPlace) {
        auxPlace.newsFeed = await getPlaceNewsFeed(auxPlace.id)
        auxPlace.productsData = await getPlaceProducts(auxPlace.id)
      }
      setPlace(auxPlace)
      return auxPlace
    }
  }
  const { isLoading } = useFirestoreQuery(query, setDataFunc)

  return {
    placeLoading: isLoading,
    place,
    query,
  }
}

export const usePlaceById = id => {
  const [place, setPlace] = useState(null)
  const ref = db.collection('places').doc(id)
  const { isLoading } = useFirestoreDoc(ref, setPlace)
  return {
    placeLoading: isLoading,
    place,
  }
}
