import { notification } from 'antd'
import { $, textStore } from '../../stores/localization/localization'

export const handleError = (e, description, callback) => {
  let message = description || $(textStore.getState().keys.genericError)
  if (e) {
    if (e.response) {
      message = e.response.message
    } else if (e.message) {
      message = e.message
    }
  }
  notification['error']({
    message: $(textStore.getState().keys.error),
    description: message,
  })
  if (callback) callback()
}
