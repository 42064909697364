export const PlaceType = {
  CRAFTSMAN: 'craftsman',
  FARMER: 'farmer',
  GROCERY: 'grocery',
  RESTAURANT: 'restaurant',
  BUTCHER: 'butcher',
  BAKERY_PASTRY: 'bakeryPastry',
  DRINKS: 'drinks',
  FOOD: 'food',
  CHEESE_STORE: 'cheeseStore',
  FISH_STORE: 'fishStore',
  CATERER: 'caterer',
  NON_FOOD: 'nonFood',
  OTHER: 'other',
}
