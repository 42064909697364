import React from 'react'
import { MODE_LIST } from '../../pages/heroes/Heroes'
import { localizedUrl } from '../../stores/localization/localization'
import { Layout } from 'antd'
import NavHeader from './NavHeader'
import { menuStore } from '../../stores/menuStore'
import { useLocation } from 'react-router-dom'
import { AppRoutes } from '../../utils/constants/Routing'

function PageNavBar() {
  const { mapMode } = menuStore()
  const location = useLocation()

  return (
    <>
      {(mapMode === MODE_LIST || location.pathname !== localizedUrl(AppRoutes.Heroes)) && (
        <Layout.Header>
          <NavHeader />
        </Layout.Header>
      )}
    </>
  )
}

export default PageNavBar
