import TagManager from 'react-gtm-module'

export const trackPageView = () => {
  const args = {
    dataLayer: {
      event: 'custom-page-view',
    },
  }
  TagManager.dataLayer(args)
}

// The event name have the first letter capitalized to make it consistent across project
export const trackGTMEvent = (category, action, label) => {
  const args = {
    dataLayer: {
      event: 'react-event',
      reactCategory: category?.[0].toUpperCase() + category?.slice(1).toLowerCase(),
      reactAction: action?.[0].toUpperCase() + action?.slice(1).toLowerCase(),
      reactLabel: label?.[0].toUpperCase() + label?.slice(1).toLowerCase(),
    },
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('GTM event', args.dataLayer)
  }

  TagManager.dataLayer(args)
}
